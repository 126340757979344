const initState = {
  transactions: [],
  transactionsSearch: [],
  loader: false
};

const transactionReducer = (state = initState, action) => {
  switch (action.type) {
    case "Transactions":
      return {
        ...state,
        transactions: action.data,
        transactionsSearch: action.data,
      };
    case "filterTransactionID":
      let res = state.transactionsSearch.filter((val) => {
        return (
          val.id.toLowerCase().includes(action.data.toLowerCase()) ||
          val.amount.toString().includes(action.data)
        );
      });
      return {
        ...state,
        transactions: res,
      };
    case "LoadTransactions":
      return {
        ...state,
        loader: true,
      };
    case "LoadStop":
      return {
        ...state,
        loader: false,
      };
    case "UserTransactions":
      return {
        ...state,
        transactions: action.data,
      };
    default:
      return state;
  }
};

export default transactionReducer;

import React, { useEffect, useState} from 'react';
import UserSideBar from "../../components/Sidebar/sidebar";
import profile from "../../assets/images/user.svg";
import { connect } from 'react-redux'
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ActivateUser, getUserById, SuspendUser } from '../../store/actions/admin';
import { Link, useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { filterTransactionID, getUserTransactions } from '../../store/actions/transactions';
import Moment from "react-moment";

const UserDetails = ({
  user,
  HandleSuspend,
  HandleActivate,
  details,
  id,
  getTransact,
  transactions,
  loader,
  searchTransact,
  getUser,
  userprofile
}) => {
  // make call to fetch all transactions
  useEffect(() => {
    getTransact(id);
    getUser(id)
  }, [getTransact, id, getUser]);

  const history = useHistory();

  const [search, setSearchValue] = useState("");

  const columns = [
    {
      name: "Username",
      selector: (row) => row.sellerDetails.username,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Amount",
      cell: (row) => <span>{`${row.sellerDetails.countryTag === "GH" ? "GHS" : "NGN"} ${row.amount}`}</span>,
    },
    {
      name: "Date",
      cell: (row) => (
        <span>
          <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
        </span>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
      ),
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            ViewRequest(row.id);
          }}
        >
          View
        </button>
      ),
    },
  ];

  const column1 = [
    {
      name: "Username",
      selector: (row) => row.username,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Date Added",
      cell: (row) => (
        <span>
          <Moment format="MMMM Do, YYYY">{row.updatedAt}</Moment>
        </span>
      ),
    }
  ];


  const ViewRequest = (id) => {
    history.push("/transaction/" + id);
  };

  const getStatusColor = (val) => {
    let result;
    switch (val) {
      case "Pending":
        result = "pending-icon";
        break;
      case "Successful":
        result = "completed-icon";
        break;
      case "Failed":
        result = "failed-icon";
        break;
      default:
        result = "failed-icon";
        break;
    }
    return result;
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    searchTransact(search);
  }, [search, searchTransact]);

  const accountLayout =
    details && details.length ? (
      details.map((value) => {
        return (
          <div key={value.id} className="col-lg-6 mt-4">
            <div className="account-div">
              <p
                className="mb-0"
                style={{ color: "#2C3A50", fontWeight: "bold" }}
              >
                {value.accountType}
              </p>
              <p
                className="mb-0 mt-1"
                style={{ color: "#2C3A50", fontWeight: "500" }}
              >
                {value.accountType === "Bank"
                  ? value.accountNumber
                  : value.mobileNumber}
              </p>
              <p
                className="mb-0 mt-1"
                style={{ color: "#2C3A50", fontWeight: "500" }}
              >
                { value.accountName}
              </p>
              <p
                className="mb-0 mt-1"
                style={{ color: "#2C3A50", fontWeight: "500" }}
              >
                {value.accountType === "Bank"
                  ? value.bankName
                  : value.mobileNetwork}
              </p>
            </div>
          </div>
        );
      })
    ) : (
      <div></div>
    );

  const Suspend = (id, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `You are about to ${
        status ? "suspend" : "restore"
      } this user, Do you wish to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => Approval(id, status),
        },
        {
          label: "No",
        },
      ],
    });
  };

  // function to restore or suspend
  const Approval = (id, status) => {
    const values = {
      userId: id,
    };
    if (status) {
      HandleSuspend(values);
    } else {
      HandleActivate(values);
    }
  };

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <Link
                to="/users"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#FF8400",
                  textDecoration: "none",
                }}
              >
                <i
                  className="mdi mdi-chevron-left mr-1"
                  style={{ fontSize: 20 }}
                ></i>
                <p className="mb-0" style={{ fontSize: 14 }}>
                  Go Back
                </p>
              </Link>

              <div>
                <h4
                  className="text-center mt-3 mt-md-2"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Profile Details
                </h4>
              </div>

              <div className="mt-4 profile-div">
                <div className="text-center text-md-left">
                  <img
                    src={user.imageUrl ? user.imageUrl : profile}
                    className="userImage"
                    alt="profile-pix"
                  />
                </div>

                <div className="text-center text-md-left">
                  <div className="">
                    <div className="mt-4 mt-lg-0">
                      <h6>Wallet Balance {user.countryTag === "GH" ? "(GHS)" : "(NGN)"}</h6>
                    </div>

                    <div className="mt-3">
                      <h5 style={{ fontWeight: 800, color: "#2C3A50" }}>
                        {user.walletBalance ? user.walletBalance.toFixed(2) : 0}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* Username */}
              <div className="text-center mt-5">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                    <p
                   className="mb-0"
                   style={{ color: "#1a4295", fontWeight: "700", fontSize: 12 }}>
                   Username
                  </p>
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {user.username ? user.username : "Anonymous"}
                  </p>
                </div>
              </div>

              {/* Phone Number */}
              <div className="text-center mt-3">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                    <p
                   className="mb-0"
                   style={{ color: "#1a4295", fontWeight: "700", fontSize: 12 }}>
                    Phone Number
                  </p>
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {user.phoneNumber ? user.phoneNumber : "+233 0000 0000"}
                  </p>
                </div>
              </div>

              {/* email */}
              <div className="text-center mt-3">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                    <p
                   className="mb-0"
                   style={{ color: "#1a4295", fontWeight: "700", fontSize: 12 }}>
                    Email Address
                  </p>
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {user.email ? user.email : "your@email.com"}
                  </p>
                </div>
              </div>

              {/* country */}
              <div className="text-center mt-3">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                    <p
                   className="mb-0"
                   style={{ color: "#1a4295", fontWeight: "700", fontSize: 12 }}>
                    Country
                  </p>
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {user.country ? user.country : "Country"}
                  </p>
                </div>
              </div>

               {/* country */}
               <div className="text-center mt-3">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                    <p
                   className="mb-0"
                   style={{ color: "#1a4295", fontWeight: "700", fontSize: 12 }}>
                    Ref code
                  </p>
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {user.referralCode ? user.referralCode : "Referral Code"}
                  </p> 
                </div>
              </div>

              {/* status */}
              <div className="text-center mt-3">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                    <p
                   className="mb-0"
                   style={{ color: "#1a4295", fontWeight: "700", fontSize: 12 }}>
                     Status
                  </p>
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {user.isEnabled ? "Active" : "Inactive"}
                  </p>
                </div>
              </div>

              <div className="text-center mt-4">
                <p
                  className="mb-0"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Account Details
                </p>
              </div>

              <div className="row">{accountLayout}</div>

              {details && details.length === 0 ? (
                <div
                  className="text-center mt-2"
                  style={{ fontStyle: "italic", fontSize: 14 }}
                >
                  <p>There is no account details attached yet!</p>
                </div>
              ) : (
                ""
              )}

              <div className="text-center mt-lg-5 mt-4">
                <button
                  type="submit"
                  onClick={() => {
                    Suspend(user.id, user.isEnabled);
                  }}
                  className={
                    user.isEnabled
                      ? "btn btn-suspend_submit"
                      : "btn btn-restore_submit"
                  }
                  style={{ width: "auto" }}
                >
                  {user.isEnabled ? "Suspend User" : "Restore User"}
                </button>
              </div>
            </div>
          </div>

          {/* user's transactions display */}
          <div className="transactions-container mt-5 mb-5">
            {/* filter layout and search */}
            <div className="transaction-filter-container">
              <div className="transaction-search">
                <div className="form-group search-container mb-3">
                  <input
                    className="form-control input-style"
                    type="text"
                    placeholder="Search by Transaction Reference, Amount"
                    id="reference"
                    value={search}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <DataTable
              title="Transactions History"
              columns={columns}
              data={transactions}
              pagination
              persistTableHead
              progressPending={loader}
            />
          </div>


          {/* users referral users */}
           <div className="transactions-container mt-5 mb-5">
            <DataTable
              title="Referrals History"
              columns={column1}
              data={userprofile}
              pagination
              persistTableHead
              progressPending={loader}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
   const id = ownProps.match.params.id;
  return {
    id,
    user: state.admin.user,
    userprofile: state.admin.userprofile,
    details: state.admin.user.accountDetails,
    transactions: state.transactions.transactions,
    loader: state.transactions.loader
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    HandleSuspend: (value) => dispatch(SuspendUser(value)),
    HandleActivate: (value) => dispatch(ActivateUser(value)),
    getTransact: (id) => dispatch(getUserTransactions(id)),
    getUser: (id) => dispatch(getUserById(id)),
    searchTransact: (val) => dispatch(filterTransactionID(val)),
  };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
import React, { useState, useEffect } from "react";
import UserSideBar from "../../components/Sidebar/sidebar";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { AddRateValidator } from "../../validationSchema/validator";
import {connect} from 'react-redux'
import { addRate, clearRateSuccess, getCoinTypes } from "../../store/actions/rates";
import DataTable from "react-data-table-component";
import cogoToast from "cogo-toast";

const AddRates = ({ add, ratesuccess, clearRate, coinTypeFetch, cointype }) => {

  const [initialTab, setTab] = useState(1);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      coinType: "",
      USDRate: "",
      GHSRate: "",
      NGNRate: "",
      minimumUsdValue: "",
      maximumUsdValue: ""
    },
    validationSchema: AddRateValidator,
    onSubmit(values, { setSubmitting }) {
      handSubmit(values, setSubmitting);
    },
  });


  const columns = [
    {
      name: "Min USD",
      selector: (row) => row.minimumUsdValue,
    },
    {
      name: "Max USD",
      selector: (row) => <span>{`${row.maximumUsdValue}`}</span>,
    },
    {
      name: "GHS Rate",
      selector: (row) => <span>{`${row.cedisRateUsd}`}</span>,
    },
    {
      name: "NGN Rate",
      selector: (row) => <span>{`${row.ngnRateUsd}`}</span>,
    },
    {
      name: "Actions",
      button: true,
      cell: (row,index) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            handleRemove(row, index);
          }}
        >
          Remove
        </button>
      ),
    },
  ];

  const [addedRate, setAddedRate] = useState([])

  const [coinType, setCoinType] = useState("")
  const [usdRate, setUsdRate] = useState(0)

  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  const handleUSDRate = (val) =>{
    setUsdRate(val)
  }

  const handleCoinType = (val) =>{
    setCoinType(val)
    setUsdRate(0)
    setAddedRate([])
    setFieldValue("USDRate", "")
    setFieldValue("minimumUsdValue", "")
    setFieldValue("maximumUsdValue", "")
    setFieldValue("GHSRate", "")
    setFieldValue("NGNRate", "")
  }

  const history = useHistory();

  useEffect(() => {
    coinTypeFetch()
  },[coinTypeFetch])


 

  const [tabData] = useState([
    { id: 1, name: "tab-1", text: "New Rates" },
    { id: 2, name: "tab-2", text: "View Rates" },
  ]);
  // tab Layout
  const tabLayout = tabData.map((item) => (
    <div
      key={item.id}
      className={
        initialTab === item.id ? "activeOrderTab tabSpace" : "orderTab tabSpace"
      }
      onClick={() => handleToggle(item.id)}
    >
      <p className="mb-0 text-center">{item.text}</p>
    </div>
  ));

  const handleToggle = (id) => {
    setTab(id);
    if (id === 1) {
      history.push("/rates/add");
    } else {
      history.push("/rates/all");
    }
  };

  const handSubmit = async (values) => {
      let minValue = values.minimumUsdValue
      let maxValue = values.maximumUsdValue

      // check if min/max value already exist in prev array
      const found = addedRate.some(el => parseFloat(el.minimumUsdValue) === parseFloat(minValue) || parseFloat(el.maximumUsdValue) === parseFloat(maxValue));
      if(found){
        cogoToast.warn("There already exist a minimum or maximum value added!")
      }
      else{
          // Push newly added values into the added rate array 
          let res = {
            minimumUsdValue: parseFloat(minValue),
            maximumUsdValue: parseFloat(maxValue),
            ngnRateUsd: parseFloat(values.NGNRate),
            cedisRateUsd: parseFloat(values.GHSRate)
          }
        setAddedRate([
          ...addedRate,
          res
        ])
      }
  };

  const handleRemove = (row, index) =>{
    let res = [...addedRate]
    res.splice(index, 1)
    setAddedRate(res)
  }

  const handleAddRate = async () =>{
    setIsSaveDisabled(true)  
    let result = {
      localCurrencyRate: addedRate,
      usdRateCoin: usdRate,
      coinType: coinType
    }
    await add(result)

    setTimeout(()=>{
      setIsSaveDisabled(false)
    }, 3000)
  }

  useEffect(() => {
    if (ratesuccess) {
      // reset the form
      setTimeout(() => {
        clearRate();
        history.push('/rates/all')
      }, 2000);
    }
  }, [ratesuccess, clearRate, history]);

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          {/* order tabs select */}
          <div
            className="mt-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {tabLayout}
          </div>

          <div className="admin-card mt-5 mb-5">
            <div className="rates-div">


              <div>
                <h4
                  className="text-center"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Create New Rate
                </h4>
              </div>

              <div className="row">
                <div className="col-lg-6">

                    {/* form submission */}
        
                  <form onSubmit={handleSubmit}>
                    {/* Coin Name */}
                    <div className="form-group mt-lg-3 input-container">
                      <label htmlFor="coinType" style={{color: 'rgb(44, 58, 80)' }}>Coin Name</label>
                      <select
                        name="coinType"
                        values={values.coinType}
                        onChange={(e) => {
                          handleChange(e);
                          handleCoinType(e.currentTarget.value)
                        }}
                        onBlur={handleBlur}
                        defaultValue=""
                        className="form-control date-select-style"
                        id="coinType"
                      >
                        <option value="" disabled>Select a coin type</option>
                        {cointype.map((opt, index) => {
                          return (
                            <option key={index} value={opt.coinType}>
                              {opt.coinType}
                            </option>
                          );
                        })}
                      </select>
                      <small style={{ color: "#dc3545" }}>
                        {touched.coinType && errors.coinType}
                      </small>
                    </div>

                    {/* usd rate */}
                    <div className="form-group input-container mt-3">
                      <label htmlFor="USDRate" style={{color: 'rgb(44, 58, 80)' }}>USD Rate</label>
                      <input
                        className="form-control input-style"
                        type="text"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          handleUSDRate(e.currentTarget.value);
                        }}
                        id="USDRate"
                        style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                        value={values.USDRate}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.USDRate && errors.USDRate}
                      </small>
                    </div>

                    
                    
                    <div className="mt-4">
                      <small>
                      <i
                        className="mdi mdi-information mr-1"
                        style={{ color: "#FF8400" }}
                      ></i>
                      Kindly add a minimum and a maximum breakdown here.
                    </small>
                    </div>

                    <div className="row mt-1">
                      <div className="col-lg-6">
                          {/* ghs rate */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="minimumUsdValue" style={{color: 'rgb(44, 58, 80)' }}>Minimum USD Amount</label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="minimumUsdValue"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.minimumUsdValue}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.minimumUsdValue && errors.minimumUsdValue}
                          </small>
                        </div>

                      </div>

                      <div className="col-lg-6">
                              {/* Ngn rate */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="maximumUsdValue" style={{color: 'rgb(44, 58, 80)' }}>Maximum USD Amount</label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="maximumUsdValue"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.maximumUsdValue}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.maximumUsdValue && errors.maximumUsdValue}
                          </small>
                      </div>

                      </div>

                    </div>


                    <div className="row">
                      <div className="col-lg-6">
                          {/* ghs rate */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="GHSRate" style={{color: 'rgb(44, 58, 80)' }}>GHS Rate</label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="GHSRate"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.GHSRate}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.GHSRate && errors.GHSRate}
                          </small>
                        </div>

                      </div>

                      <div className="col-lg-6">
                              {/* Ngn rate */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="NGNRate" style={{color: 'rgb(44, 58, 80)' }}>NGN Rate</label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="NGNRate"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.NGNRate}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.NGNRate && errors.NGNRate}
                          </small>
                      </div>

                      </div>

                    </div>   
                
                    <button
                      type="submit"
                      disabled={isSaveDisabled}
                      className="btn btn-login_submit btn-block mt-4"
                    >
                      Add
                    </button>
                    <small>
                      <i
                        className="mdi mdi-information mr-1"
                        style={{ color: "#FF8400" }}
                      ></i>
                      The coin name selected is what is displayed to the user.
                    </small>
                  </form>
               
                </div>

                <div className="col-lg-6">

                  <div className="mt-4">
                  <DataTable
                    title="Added Rates"
                    columns={columns}
                    data={addedRate}
                    pagination
                    persistTableHead
                    progressPending={false}
                  />
                  </div>

                  <div>
                  <button
                      type="submit"
                      className="btn btn-login_submit btn-block mt-4"
                      onClick={handleAddRate}
                      disabled={addedRate.length === 0 || isSaveDisabled}
                    >
                      Save Rate
                    </button>
                  </div>

                </div>

              </div>

            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ratesuccess: state.rates.ratesuccess,
    cointype: state.rates.cointypes,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (creds) => dispatch(addRate(creds)),
    clearRate: () => dispatch(clearRateSuccess()),
    coinTypeFetch: () => dispatch(getCoinTypes()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRates);

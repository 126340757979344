import React, { useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
import UserSideBar from "../../components/Sidebar/sidebar";
import DataTable from "react-data-table-component";
import {connect} from 'react-redux'
import { ActivateAdmin, getAllAdmins, SuspendAdmin } from '../../store/actions/admin';
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ViewAdmin = ({
  adminFetch,
  admins,
  HandleSuspend,
  HandleActivate,
  userRole,
}) => {
  const [initialTab, setTab] = useState(2);

  const [role] = useState("SubAdmin");

  // make call to fetch all admins
  useEffect(() => {
    adminFetch(role);
  }, [adminFetch, role]);

  const history = useHistory();

  const [tabData] = useState([
    { id: 1, name: "tab-1", text: "New Admin" },
    { id: 2, name: "tab-2", text: "View Admin" },
  ]);

  // tab Layout
  const tabLayout = tabData.map((item) => (
    <div
      key={item.id}
      className={
        initialTab === item.id ? "activeOrderTab tabSpace" : "orderTab tabSpace"
      }
      onClick={() => handleToggle(item.id)}
    >
      <p className="mb-0 text-center">{item.text}</p>
    </div>
  ));

  const handleToggle = (id) => {
    setTab(id);
    if (id === 1) {
      history.push("/admin");
    } else {
      history.push("/admin/all");
    }
  };

  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },
    {
      name: "Date Registered",
      cell: (row) => (
        <span>
          <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
        </span>
      ),
    },
    {
      name: "Status",
      cell: (row) => <span>{row.isEnabled ? "Active" : "Inactive"}</span>,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <button
          className="btn btn-sm btn-view"
          disabled={userRole === "SubAdmin"}
          onClick={() => {
            Suspend(row.id, row.isEnabled);
          }}
        >
          {row.isEnabled ? "Suspend" : "Restore"}
        </button>
      ),
    },
  ];

  const Suspend = (id, status) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `You are about to ${
        status ? "suspend" : "restore"
      } this user, Do you wish to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => Approval(id, status),
        },
        {
          label: "No",
        },
      ],
    });
  };

  // function to restore or suspend
  const Approval = (id, status) => {
    const values = {
      userId: id,
    };
    if (status) {
      HandleSuspend(values);
    } else {
      HandleActivate(values);
    }
  };

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          {/* order tabs select */}
          <div
            className="mt-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {tabLayout}
          </div>

          <div className="transactions-container mt-5 mb-5">
            <DataTable
              title="Administrators"
              columns={columns}
              data={admins}
              pagination
              persistTableHead
              progressPending={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    admins: state.admin.admins,
    userRole: state.auth.role,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    adminFetch: (val) => dispatch(getAllAdmins(val)),
    HandleSuspend: (value) => dispatch(SuspendAdmin(value)),
    HandleActivate: (value) => dispatch(ActivateAdmin(value)),
  };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ViewAdmin);
const initState = {
  requests: [],
  requestSearch: [],
  request: {},
  loader: false,
  loading: false,
};

const requestReducer = (state = initState, action) => {
  switch (action.type) {
    case "Requests":
      return {
        ...state,
        requests: action.data,
        requestSearch: action.data
      };
    case "searchRequests":
      let res = state.requestSearch.filter((val) => {
        return (
          val.userDetails.username
            .toLowerCase()
            .includes(action.data.toLowerCase()) ||
          val.amount.toString().includes(action.data) ||
          val.accountType.toLowerCase().includes(action.data.toLowerCase())
        );
      });
      return {
        ...state,
        requests: res,
      };
    case "filterRequests":
      const request = state.requests.find((val) => val.id === action.data);
      return {
        ...state,
        request,
      };
    case "Request":
      return {
        ...state,
        request: action.data,
      };
    case "LoadRequest":
      return {
        ...state,
        loading: true
      }
    case "StopLoadRequest":
      return {
        ...state,
        loading: false
      }
    case "StartLoader":
      return {
        ...state,
        loader: true,
      };
    case "StopLoader":
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};

export default requestReducer;

const initState = {
  count: {},
  admins: [],
  users: [],
  usersSearch: [],
  user: {},
  userprofile: {},
  rates: [],
  config: {},
  enableAutomatedWithdrawer: false,
  loader: false,
  kycconfig: []
};

const adminReducer = (state = initState, action) => {
  switch (action.type) {
    case "Admins":
      return {
        ...state,
        admins: action.data,
      };
    case "AdminSuccess":
      let res;
      switch (action.name) {
        case "Suspend":
          res = state.admins.map((item) =>
            item.id === action.id
              ? { ...item, isEnabled: false }
              : [...state.admins]
          );
          break;
        case "Restore":
          res = state.admins.map((item) =>
            item.id === action.id
              ? { ...item, isEnabled: true }
              : [...state.admins]
          );
          break;
        default:
          break;
      }
      return {
        ...state,
        admins: res,
      };
    case "Users":
      return {
        ...state,
        users: action.data,
        usersSearch: action.data
      };
    case 'UserProfile':
      return{
        ...state,
        userprofile: action.data
      }
    case "searchUser":
      let resp = state.usersSearch.filter((val) => {
        return (
          val.username.toLowerCase().includes(action.data.toLowerCase()) ||
          val.email.toString().includes(action.data)
        );
      });
      return {
        ...state,
        users: resp,
      };
    case "filterUsers":
      const user = state.users.find((val) => val.id === action.data);
      return {
        ...state,
        user,
      };
    case "UserSuccess":
      let result;
      switch (action.name) {
        case "Suspend":
          result = {
            ...state.user,
            isEnabled: false
          }
          break;
        case "Restore":
          result = {
            ...state.user,
            isEnabled: true,
          };
          break;
        default:
          break;
      }
      return {
        ...state,
        user: result,
      };
    case "OverviewCount":
      return {
        ...state,
        count: action.data,
      };
    case "Rates":
      return {
        ...state,
        rates: action.data,
      };
    case "StartLoader":
      return {
        ...state,
        loader: true
      }
    case "StopLoader":
      return {
        ...state,
        loader: false
      }
    case "Config":
      return {
        ...state,
        config: action.data,
        enableAutomatedWithdrawer: action.data.enableAutomatedWithdrawer,
      };
    case "handleToggle":
      return {
        ...state,
        enableAutomatedWithdrawer: action.data,
      };
    case "KYCSettings":
      return{
        ...state,
        kycconfig: action.data
      }
    case "KYC_Update":
      return{
        ...state,
      }
    default:
      return state;
  }
};

export default adminReducer;

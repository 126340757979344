import React, {useRef} from 'react';
import UserSideBar from "../../components/Sidebar/sidebar";
import profile from "../../assets/images/user.svg";
import { Form, Formik } from "formik";
import {
  ChangePasswordValidator,
} from "../../validationSchema/validator";
import {connect} from 'react-redux'
import { ChangePassword, UploadPhoto } from '../../store/actions/auth';

const Profile = ({
  username,
  phoneNumber,
  email,
  savePassword,
  photoloader,
  imageUrl,
  handlePicture,
}) => {
  const fileRef = useRef(null);

  const handleFile = () => {
    var file = fileRef.current.files[0];
    handlePicture(file);
  };

  const handleSubmit = async (values, setSubmitting) => {
    await savePassword(values);
  };

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          {/* profile */}
          <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <div className="text-center">
                <img
                  src={imageUrl ? imageUrl : profile}
                  className="profileImage"
                  alt="profile-pix"
                />
              </div>

              <div className="text-center mt-3">
                <label className={photoloader ? "file disabled" : "file"}>
                  <i className="mdi mdi-camera-outline mr-1"></i>{" "}
                  {imageUrl === "" ? "Upload Photo" : "Change Photo"}
                  <input
                    type="file"
                    size="60"
                    ref={fileRef}
                    className="adminphoto"
                    onChange={() => handleFile()}
                    accept="image/*"
                  />
                </label>
              </div>

              {/* Username */}
              <div className="text-center mt-4">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {username ? username : "Anonymous"}
                  </p>
                </div>
              </div>

              {/* Phone Number */}
              <div className="text-center mt-4">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {phoneNumber ? phoneNumber : "+233 0000 0000"}
                  </p>
                </div>
              </div>

              {/* email */}
              <div className="text-center mt-3">
                <div
                  style={{
                    background: "rgba(44, 58, 80, 0.2)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                  }}
                >
                  <p
                    className="mb-0"
                    style={{ color: "#2C3A50", fontWeight: "bold" }}
                  >
                    {email ? email : "your@email.com"}
                  </p>
                </div>
              </div>

              <div className="text-center mt-4">
                <p
                  className="mb-0"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Change Password ?
                </p>
              </div>

              {/* form submission */}
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={ChangePasswordValidator}
                initialValues={{
                  newpassword: "",
                  password: "",
                  confirm_password: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* current password */}
                    <div className="width">
                      <div
                        className="form-group mt-2 input-container"
                        style={{ position: "relative" }}
                      >
                        <label htmlFor="email" style={{color: 'rgb(44, 58, 80)'}}>Current Password</label>
                        <input
                          type="password"
                          name="password"
                          placeholder="Current Password"
                          style={{ color: "black" }}
                          className="form-control input-style"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="password"
                          value={values.password}
                        />

                        <small style={{ color: "#dc3545" }}>
                          {touched.password && errors.password}
                        </small>
                      </div>
                    </div>

                    {/* new password */}
                    <div className="width">
                      <div
                        className="form-group mt-3 input-container"
                        style={{ position: "relative" }}
                      >
                        <label htmlFor="email" style={{color: 'rgb(44, 58, 80)'}}>New Password</label>
                        <input
                          type="password"
                          className="form-control input-style"
                          name="newpassword"
                          placeholder="New Password"
                          style={{ color: "black" }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="newpassword"
                          value={values.newpassword}
                        />

                        <small style={{ color: "#dc3545" }}>
                          {touched.newpassword && errors.newpassword}
                        </small>
                      </div>
                    </div>

                    {/* confirm password */}
                    <div className="width">
                      <div
                        className="form-group input-container mt-3"
                        style={{ position: "relative" }}
                      >
                        <label htmlFor="email" style={{color: 'rgb(44, 58, 80)'}}>Confirm New Password</label>
                        <input
                          type="password"
                          className="form-control input-style"
                          name="confirm_password"
                          placeholder="Confirm New Password"
                          style={{ color: "black" }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="confirm_password"
                          value={values.confirm_password}
                        />

                        <small style={{ color: "#dc3545" }}>
                          {touched.confirm_password && errors.confirm_password}
                        </small>
                      </div>
                    </div>

                    <div className="text-center mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-login_submit"
                        style={{ width: "auto" }}
                      >
                        Save Password
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.profile.username,
    phoneNumber: state.auth.profile.phoneNumber,
    email: state.auth.profile.email,
    imageUrl: state.auth.imageUrl,
    photoloader: state.auth.photoloader,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    savePassword: (values) => dispatch(ChangePassword(values)),
    handlePicture: (val) => dispatch(UploadPhoto(val)),
  };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
import React,{useState, useEffect} from "react";
import UserSideBar from "../../components/Sidebar/sidebar";
import "../../styles/dashboard.css";
import DataTable from "react-data-table-component";
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import { filterUsers, getAllUsers, searchUser } from "../../store/actions/admin";
import Moment from "react-moment";

const Users = ({ usersFetch, users, usersFilter, searchUsers }) => {

  const [role] = useState("Seller");

  const [search, setSearchValue] = useState("");
  
  const history = useHistory()

  // make call to fetch all users
  useEffect(() => {
    usersFetch(role);
  }, [usersFetch, role]);

  
  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },
    {
      name: "Date Registered",
      cell: (row) => (
        <span>
          <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
        </span>
      ),
    },
    {
      name: "Status",
      cell: (row) => <span>{row.isEnabled ? "Active" : "Inactive"}</span>,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            ViewRequest(row.id);
          }}
        >
          View
        </button>
      ),
    },
  ];


  const ViewRequest = (id) => {
    usersFilter(id)
    history.push('/user/'+id)
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    searchUsers(search);
  }, [search, searchUsers]);

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          {/* datatable to show recent Transactions */}
          <div className="transactions-container mt-5 mb-5">

              <div className="transaction-filter-container">
                        <div className="transaction-search">
                            <div className="form-group search-container ">
                            <input
                                className="form-control input-style"
                                type="text"
                                placeholder="Search by Username, Email Address"
                                id="reference"
                                value={search}
                                onChange={handleChange}
                            />
                            </div>
                         </div>
                  </div>

            <DataTable
              title="Users"
              columns={columns}
              data={users}
              pagination
              persistTableHead
              progressPending={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.admin.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    usersFetch: (val) => dispatch(getAllUsers(val)),
    usersFilter: (id) => dispatch(filterUsers(id)),
    searchUsers: (val) => dispatch(searchUser(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

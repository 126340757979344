 import cogoToast from "cogo-toast";
 import { PatchApi, PostApi } from "../request";

 
 const getToken = () => {
   const token = localStorage.getItem("token");
   return token;
 };
  
// login user actions functionality
export const loginUser = (user) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(`login`, { ...user }, "", "application/json");
      if (res.status === 200) {
        var role = res.data.message.profile.role;
         switch (role) {
           case "Admin":
             dispatch({ type: "LOGIN_SUCCESS", data: res.data.message });
             cogoToast.success("Login Successful!", {
               position: "bottom-right",
             });
             break;
           case "SubAdmin":
               dispatch({ type: "LOGIN_SUCCESS", data: res.data.message });
               cogoToast.success("Login Successful!", {
                 position: "bottom-right",
               });
             break;
           default:
             cogoToast.error("User not authorized to access this page!");
             break;
         }
      }
      if (res.status === 400) {
         cogoToast.error(`${res.data.message}`);
       }
    } catch (err) {
      console.log(err);
    }
  };
};

// api to generate secreet key
export const generateAuthSecret = () => {
  return async (dispatch, getState) => {
    try {
      const res = await PatchApi("generate/auth/secret", "", getToken());
      if (res.status === 201) {
        dispatch({ type: "AuthSecretInfo", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "Secret_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// validate 2fa code
export const validateAuthSecret = (val) => {
  const values = {
    token: val.code,
  }

  return async (dispatch, getState) => {
    try {
      const res = await PatchApi("verify/auth/code", {
        ...values 
      }, getToken());
      if (res.status === 200) {
        dispatch({ type: "ValidAuthCode", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "InvalidAuthCode", err: res.data });
        cogoToast.error("The code entered is not valid or has expired!");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// logout a user
export const logOut = () => {
  return (dispatch, getState) => {
    localStorage.setItem("token", "");
    dispatch({ type: "logout", err: "User Out" });
  };
};


// forgot password functionality
export const forgotPassword = (user) => {
  return async (dispatch, getState) => {
     try {
       const res = await PostApi(`forgotpassword`, { ...user }, "", "application/json");
       if (res.status === 201) {
          cogoToast.success(
            "Check your email for password reset instructions!",
            {
              position: "top-center",
            }
          );
       }
        if (res.status === 400) {
          cogoToast.error(
            "Kindly check that the credentials entered is valid!"
          );
        }
     } catch (err) {
       console.log(err);
     }
  }
}

// verify email functionality
export const verifyEmail = (user) => {
  return async (dispatch, getState) => {
    const values = {
      verificationCode: user.code
    }
     try {
       const res = await PostApi(`verifyuser`, { ...values }, "", "application/json");
       if (res.status === 200) {
        cogoToast.success('Email verified successfully!')
       }
        if (res.status === 400) {
          cogoToast.error('Oops, looks like the code has either expired or is invalid, please request again.')
        }
     } catch (err) {
       console.log(err);
     }
  }
}

//   Change password functionality
 export const ChangePassword = (user) => {
  return async (dispatch, getState) => {
    
    const values = {
      oldPassword: user.password,
      newPassword: user.newpassword
    }
    try {
      const res = await PostApi("changepassword", { ...values }, getToken(), "application/json");
      if (res.status === 200) {
         localStorage.setItem("token", "");
         dispatch({ type: "logout", err: "User Out" });
        cogoToast.success('Password updated successfully! Kindly Login again.', { position: 'bottom-right', })
      }
      if(res.status === 400){
        cogoToast.error('Check that your old password is correct!')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// Upload a profile picture functionality
export const UploadPhoto = (value) => {
  return async (dispatch, getState) => {
    dispatch({ type: "PhotoLoader"});
      let formdata = new FormData()
      formdata.append("file", value);
    try {
      const res = await PostApi("profileimage", formdata, getToken(), "multipart/form-data");
      if (res.status === 201) {
        var image = res.data.imageUrl
        
            // actual call to update profile 
            dispatch({ type: "profilePicture", image })
            dispatch({ type: "StopPhotoLoader" });
            cogoToast.success("Profile image updated successfully!");
        
        }
        if(res.status === 400 || res.status === 404){
          cogoToast.error('Error while uploading image!')
          dispatch({ type: "StopPhotoLoader"});
        }
    } catch (err) {
      // var message = err.response.data
        console.log(err)
    }
  };
};

// sign up an admin functionality(subadmin)
export const signUpAdmin = (user) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        "subadmin/register",
        { ...user },
        getToken(),
        "application/json"
      );
      if (res.status === 201) {
        dispatch({ type: "SIGNUP_SUCCESS" });
        cogoToast.success("Admin created successfully!");
      }
      if(res.status === 400){
        dispatch({ type: "SIGNUP_FAIL", err: res.data});
        cogoToast.error('User already exist with this email!')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// reset admin signup
export const clearSignUpState = () => {
  return (dispatch, getState) => {
    dispatch({ type: "ClearSignUp",});
  };
};

import React,{useEffect} from 'react';
import "../../styles/login.css";
import Logo from "../../assets/logo/logo-white.svg";
import { Link, useHistory } from "react-router-dom";
import world from "../../assets/images/world.svg";
import { Form, Formik } from "formik";
import { twofaCodeValidator } from "../../validationSchema/validator";
import { connect } from "react-redux";
import { validateAuthSecret } from '../../store/actions/auth';

const TwoFactorLoginPage = ({validateCode,validcode}) => {

    const history = useHistory()

    const handleSubmit = async (val) =>{
        await validateCode(val)
    }

    useEffect(() => {
        if (validcode) {
            history.push("/dashboard");    
        }
      }, [validcode, history]);

    return ( 
        <>
             <div className="login-container">
        {/* first half */}
        <div className="first_half">
          <div className="first_half_container">
            {/* logo */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link to="/">
                <img
                  src={Logo}
                  style={{ height: "3rem" }}
                  className="img-fluid"
                  alt="Dart Logo"
                />
              </Link>
            </div>

            <div className="login-title">
              <h2>Authentication Code</h2>
              <h6 className="mt-2">Use your authentication app to view your security code</h6>
            </div>

            {/* form submission */}
            <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={twofaCodeValidator}
              initialValues={{ code: "", }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className="mt-4 form-container">
                    {/* email address */}
                    <div className="form-group input-container mb-3">
                      <label htmlFor="email">Authentication Code</label>
                      <input
                        className="form-control input-style"
                        type="text"
                        placeholder="Enter the 6 digits code here"
                        id="code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.code}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.code && errors.code}
                      </small>
                    </div>

                  
                    <Link
                      to="/forgotpassword"
                      className="text-right"
                      style={{ textDecoration: "none" }}
                    >
                      <p className="forgot-text">Forgot Password?</p>
                    </Link>


                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-login_submit"
                      >
                        Sign In
                      </button>
                    </div>

                    <div className="copyright text-center">
                      <p className="mb-0">
                        &copy; {new Date().getFullYear()} DartAfrica All rights
                        reserved.
                      </p>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* second half */}
        <div className="second_half">
          <img src={world} className="img-fluid" alt="sell bitcoins" />
      
          <div className='login_text'>
                <h4>Unlock a World of <br/> Endless Possibilities.</h4>
            </div>
        </div>
      </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      validcode: state.auth.validauthcode,
    };
  };
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
      validateCode: (code) => dispatch(validateAuthSecret(code)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorLoginPage);
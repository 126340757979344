import React from "react";
import UserSideBar from "../../components/Sidebar/sidebar";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const TransactionDetails = ({ transaction }) => {
  const getStatusColor = (val) => {
    let result;
    switch (val) {
      case "Pending":
        result = "pending-trade-color";
        break;
      case "Successful":
        result = "completed-trade-color";
        break;
      case "Failed":
        result = "failed-trade-color";
        break;
      default:
        result = "failed-trade-color";
        break;
    }
    return result;
  };

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <Link
                to="/transactions"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#FF8400",
                  textDecoration: "none",
                }}
              >
                <i
                  className="mdi mdi-chevron-left mr-1"
                  style={{ fontSize: 20 }}
                ></i>
                <p className="mb-0" style={{ fontSize: 14 }}>
                  Go Back
                </p>
              </Link>

              <div>
                <h4
                  className="text-center mt-4 mt-md-2"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Transaction Details
                </h4>
              </div>

              {/* details */}
              <div className="row mt-md-5 mt-4">
                {/* id and time */}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Transaction Id
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {transaction ? transaction.id : "12345"}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Date
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {transaction ? (
                        <Moment format="MMMM Do, YYYY, h:mm:ss a">
                          {transaction.createdAt}
                        </Moment>
                      ) : (
                        "Trade Date"
                      )}
                    </p>
                  </div>
                </div>

                {/* username*/}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Username
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {transaction ? transaction.sellerDetails.username : "Ade"}
                    </p>
                  </div>
                </div>

                {/* type */}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Type
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {transaction ? `${transaction.type}` : "Type"}
                    </p>
                  </div>
                </div>

                {/* amount */}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Amount
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {transaction.sellerDetails.countryTag === "GH" ? "GHS" : "NGN"} {transaction ? `${transaction.amount}` : "0"}
                    </p>
                  </div>
                </div>

                {transaction && transaction.hasOwnProperty("accountType") ? (
                  <>
                    <div className="col-lg-6 mb-4">
                      <div>
                        <p
                          className="mb-0"
                          style={{ color: "#2C3A50", fontWeight: "bold" }}
                        >
                          Account Type
                        </p>
                        <p
                          className="mb-0 mt-1"
                          style={{ color: "#2C3A50", fontSize: 14 }}
                        >
                          {transaction.accountType}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-4">
                      <div>
                        <p
                          className="mb-0"
                          style={{ color: "#2C3A50", fontWeight: "bold" }}
                        >
                          {transaction.accountType === "Bank"
                            ? "Account Number"
                            : "Mobile Number"}
                        </p>
                        <p
                          className="mb-0 mt-1"
                          style={{ color: "#2C3A50", fontSize: 14 }}
                        >
                          {transaction.accountType === "Bank"
                            ? transaction.accountNumber
                            : transaction.mobileNumber}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-4">
                      <div>
                        <p
                          className="mb-0"
                          style={{ color: "#2C3A50", fontWeight: "bold" }}
                        >
                          {transaction.accountType === "Bank"
                            ? "Bank Name"
                            : "Mobile Network"}
                        </p>
                        <p
                          className="mb-0 mt-1"
                          style={{ color: "#2C3A50", fontSize: 14 }}
                        >
                          {transaction.accountType === "Bank"
                            ? transaction.bankName
                            : transaction.mobileNetwork}
                        </p>
                      </div>
                    </div>

                    {transaction.accountType === "Bank" ? (
                      <div className="col-lg-6 mb-4">
                        <div>
                          <p
                            className="mb-0"
                            style={{ color: "#2C3A50", fontWeight: "bold" }}
                          >
                            {transaction.accountType === "Bank"
                              ? "Account Name"
                              : ""}
                          </p>
                          <p
                            className="mb-0 mt-1"
                            style={{ color: "#2C3A50", fontSize: 14 }}
                          >
                            {transaction.accountType === "Bank"
                              ? transaction.accountName
                              : ""}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                <div className="col-lg-6 mb-3">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Narration
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {transaction && transaction.hasOwnProperty("narration")
                        ? `${transaction.narration}`
                        : "Wallet Credited"}
                    </p>
                  </div>
                </div>

                {/* status */}
                <div className="col-lg-6">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Status
                    </p>
                    <div className="mt-2" style={{ display: "flex" }}>
                      <p
                        className={getStatusColor(
                          transaction ? transaction.status : "Failed"
                        )}
                        style={{ fontSize: 14 }}
                      >
                        {transaction ? transaction.status : "Status"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const transactions = state.transactions.transactions;
  const transaction = transactions.find((val) => val.id === id);
  return {
    transaction,
  };
};

export default connect(mapStateToProps)(TransactionDetails);

const initState = {
    analytics: {},
    useranalytics: [],
    usersSearch: [],
    loader: false
  };
  
  const analyticReducer = (state = initState, action) => {
    switch (action.type) {
      case "Analytics":
        return {
          ...state,
          analytics: action.data,
        };
    case 'Analytics_Error':
      return{
        ...state,
      }
    case 'Loader':
        return{
            ...state,
            loader: true
        }
       case "UserAnalytics":
           return{
               ...state,
               useranalytics: action.data,
               usersSearch: action.data,
               loader: false
           }
        case 'UserAnalytics_Error':
          return{
            ...state,
            loader: false
          }
        case "searchUser":
        let resp = state.usersSearch.filter((val) => {
            return (
            val.username.toLowerCase().includes(action.data.toLowerCase())
            );
        });
        return {
            ...state,
            useranalytics: resp,
        };
      default:
        return state;
    }
  };
  
  export default analyticReducer;
  
import React, {useState} from 'react';
import UserSideBar from "../../components/Sidebar/sidebar";
import { Form, Formik } from "formik";
import { AddRateValidator } from "../../validationSchema/validator";
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { updateRate } from '../../store/actions/rates';
import DataTable from "react-data-table-component";
import cogoToast from "cogo-toast";
import Modal from "../../components/ModalComponent/Modal";


const EditRates = ({ rate, update, id, currencyRate }) => {
  
  const [showEditModal, setEditModal] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [editminimumUsdValue, setEditMinUsd] = useState(0);
  const [editmaximumUsdValue, setEditMaxUsd] = useState(0);
  const [editNGNRate, setEditNGNRate] = useState(0);
  const [editGHSRate, setEditGHSRate] = useState(0);

  const [editMinUSdError, setEditMinUSdError] = useState(false)
  const [editMaxUSdError, setEditMaxUSdError] = useState(false)
  const [editNGNError, setEditNGNError] = useState(false)
  const [editGHSError, setEditGHSError] = useState(false)

  const updateRateVal = (e) =>{
    e.preventDefault()
  
    let res = {
      minimumUsdValue: parseFloat(editminimumUsdValue),
      maximumUsdValue: parseFloat(editmaximumUsdValue),
      ngnRateUsd: parseFloat(editNGNRate),
      cedisRateUsd: parseFloat(editGHSRate)
    }

    if(editminimumUsdValue === ""){
      setEditMinUSdError(true)
    }
    else if(editmaximumUsdValue === ""){
      setEditMaxUSdError(true)
    }
    else if(editNGNRate === ""){
      setEditNGNError(true)
    }
    else if(editGHSRate === ""){
      setEditGHSError(true)
    }
    else{
      setEditMinUSdError(false)
      setEditMaxUSdError(false)
      setEditNGNError(false)
      setEditGHSError(false)
      let result = addedRate.map((item, index) => index === currentIndex ? 
        {
          ...res
        }
      : item);
    
        setAddedRate(result)
        setEditModal(false)
    }  
  }

  const handleDisabled = (type) => {
    if (type === "BITCOINCASH") {
      return false
    }
    else {
      return true
    }
  }

  const columns = [
    {
      name: "Min USD",
      selector: (row) => row.minimumUsdValue,
    },
    {
      name: "Max USD",
      selector: (row) => <span>{`${row.maximumUsdValue}`}</span>,
    },
    {
      name: "GHS Rate",
      selector: (row) => <span>{`${row.cedisRateUsd}`}</span>,
    },
    {
      name: "NGN Rate",
      selector: (row) => <span>{`${row.ngnRateUsd}`}</span>,
    },
    {
      name: "Actions",
      button: true,
      cell: (row,index) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => handleEditModal(row, index)}
        >
          Edit
        </button>
      ),
    },
    {
      name: "",
      button: true,
      cell: (row,index) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            handleRemove(row, index);
          }}
        >
          Remove
        </button>
      ),
    },
  ];

  const handleEditModal = (row, index) =>{
    // find and populate the modal with the values
     let val = addedRate[index]
     setCurrentIndex(index)
     setEditMinUsd(val.minimumUsdValue)
     setEditMaxUsd(val.maximumUsdValue)
     setEditGHSRate(val.cedisRateUsd)
     setEditNGNRate(val.ngnRateUsd)

      setEditModal(true)
  }

  
  const [addedRate, setAddedRate] = useState(currencyRate)

  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  const handleRemove = (row, index) =>{
    let res = [...addedRate]
    res.splice(index, 1)
    setAddedRate(res)
  }


  const handleSubmit = async (values) => {
    let minValue = values.minimumUsdValue
    let maxValue = values.maximumUsdValue

      // check if min/max value already exist in prev array
      const found = addedRate.some(el => parseFloat(el.minimumUsdValue) === parseFloat(minValue) || parseFloat(el.maximumUsdValue) === parseFloat(maxValue));
      if(found){
        cogoToast.warn("There already exist a minimum or maximum value added!")
      }
      else{
          // Push newly added values into the added rate array 
          let res = {
            minimumUsdValue: parseFloat(minValue),
            maximumUsdValue: parseFloat(maxValue),
            ngnRateUsd: parseFloat(values.NGNRate),
            cedisRateUsd: parseFloat(values.GHSRate)
          }
        setAddedRate([
          ...addedRate,
          res
        ])
      }
    // await update(creds);
  };

  const handleUpdateRate = async () =>{
    setIsSaveDisabled(true)  
    let result = {
      id: id,
      localCurrencyRate: addedRate,
      usdRateCoin: rate.usdRateCoin,
      coinType: rate.coinType
    }
    await update(result)

    setTimeout(()=>{
      setIsSaveDisabled(false)
    }, 3000)
  }
  
  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">

         {/* dialog for edit individual rates */}
         <Modal
            title="Edit"
            show={showEditModal}
            onClose={() => setEditModal(false)}
          >
            <div>
                  <form onSubmit={updateRateVal}>
                    {/* min usd*/}
                    <div className="mt-4">      
                      <div className="form-group input-container mt-3">
                            <label htmlFor="editminimumUsdValue" style={{color: 'rgb(44, 58, 80)' }}>Minimum USD Amount</label>
                            <input
                              className="form-control input-style"
                              type="text"
                              placeholder="0"
                              id="editminimumUsdValue"
                              style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                              onChange={(e) => setEditMinUsd(e.target.value)}
                              value={editminimumUsdValue}
                            />
                           { editMinUSdError && <small style={{ color: "#dc3545" }}>
                             Minimum USD Rate is required
                          </small>}
                        </div>

                        <div className="form-group input-container mt-3">
                            <label htmlFor="editmaximumUsdValue" style={{color: 'rgb(44, 58, 80)' }}>Maximum USD Amount</label>
                            <input
                              className="form-control input-style"
                              type="text"
                              placeholder="0"
                              id="editmaximumUsdValue"
                              style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                              value={editmaximumUsdValue}
                              onChange={(e) => setEditMaxUsd(e.target.value)}
                            />
                          { editMaxUSdError && <small style={{ color: "#dc3545" }}>
                           Maximum USD Rate is required
                          </small>
                          }
                        </div>

                        <div className="form-group input-container mt-3">
                            <label htmlFor="editGHSRate" style={{color: 'rgb(44, 58, 80)' }}>GHS Rate</label>
                            <input
                              className="form-control input-style"
                              type="text"
                              placeholder="0"
                              id="editGHSRate"
                              style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                              value={editGHSRate}
                              onChange={(e) => setEditGHSRate(e.target.value)}
                            />
                            { editGHSError &&  <small style={{ color: "#dc3545" }}>
                              GHS Rate is required
                             </small>
                              }
                        </div>

                        <div className="form-group input-container mt-3">
                            <label htmlFor="editNGNRate" style={{color: 'rgb(44, 58, 80)' }}>NGN Rate</label>
                            <input
                              className="form-control input-style"
                              type="text"
                              placeholder="0"
                              id="editNGNRate"
                              style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                              value={editNGNRate}
                              onChange={(e) => setEditNGNRate(e.target.value)}
                            />
                            { editNGNError && <small style={{ color: "#dc3545" }}>
                              NGN Rate is required
                             </small>
                              }
                        </div>
                
                    </div>

                    <div className="text-center mt-4 ">
                      <button
                        type="submit"
                        className="btn btn-login_submit"
                        style={{ fontWeight: "normal" }}
                      >
                        Update
                      </button>
                    </div>
                  </form>
              
            </div>
          </Modal>
          {/* end of filter dialog */}

          <div className="admin-card mt-5 mb-5">
            <div className="rates-div">
              <Link
                to="/rates/all"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#FF8400",
                  textDecoration: "none",
                }}
              >
                <i
                  className="mdi mdi-chevron-left mr-1"
                  style={{ fontSize: 20 }}
                ></i>
                <p className="mb-0" style={{ fontSize: 14 }}>
                  Go Back
                </p>
              </Link>
              <div>
                <h4
                  className="text-center"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Edit Rate
                </h4>
              </div>

              <div className="row">
                <div className="col-lg-5">

                   {/* form submission */}
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={AddRateValidator}
                initialValues={{
                  coinType:
                    rate && rate.hasOwnProperty("coinType")
                      ? rate.coinType
                      : "",
                  USDRate:
                    rate && rate.hasOwnProperty("usdRateCoin")
                      ? rate.usdRateCoin
                      : 0,
                  GHSRate: "",
                  NGNRate: "",
                  minimumUsdValue: "",
                  maximumUsdValue: ""
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* Coin Name */}
                    <div className="form-group input-container mt-4">
                      <label htmlFor="coinType" style={{color: 'rgb(44, 58, 80)' }}>Coin Name</label>
                      <input
                        className="form-control input-style"
                        type="text"
                        placeholder="Enter a coin name e.g BTC,ETH"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="coinType"
                        style={{ width: "100%", color: 'rgb(44, 58, 80)' }}
                        value={values.coinType}
                        disabled
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.coinType && errors.coinType}
                      </small>
                    </div>

                    {/* usd rate */}
                    <div className="form-group input-container mt-3">
                      <label htmlFor="USDRate" style={{color: 'rgb(44, 58, 80)' }}>USD Rate</label>
                      <input
                        className="form-control input-style"
                        type="text"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="USDRate"
                        style={{ width: "100%", color: 'rgb(44, 58, 80)' }}
                        value={values.USDRate}
                        disabled={handleDisabled(rate.coinType)}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.USDRate && errors.USDRate}
                      </small>
                    </div>

                     
                    <div className="mt-4">
                      <small>
                      <i
                        className="mdi mdi-information mr-1"
                        style={{ color: "#FF8400" }}
                      ></i>
                      Kindly add a minimum and a maximum breakdown here.
                    </small>
                    </div>

                    <div className="row mt-1">
                      <div className="col-lg-6">
                          {/* ghs rate */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="minimumUsdValue" style={{color: 'rgb(44, 58, 80)' }}>Minimum USD Amount</label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="minimumUsdValue"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.minimumUsdValue}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.minimumUsdValue && errors.minimumUsdValue}
                          </small>
                        </div>

                      </div>

                      <div className="col-lg-6">
                              {/* Ngn rate */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="maximumUsdValue" style={{color: 'rgb(44, 58, 80)' }}>Maximum USD Amount</label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="maximumUsdValue"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.maximumUsdValue}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.maximumUsdValue && errors.maximumUsdValue}
                          </small>
                      </div>

                      </div>

                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                          {/* ghs rate */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="GHSRate" style={{color: 'rgb(44, 58, 80)' }}>GHS Rate</label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="GHSRate"
                            style={{ width: "100%" ,color: 'rgb(44, 58, 80)'}}
                            value={values.GHSRate}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.GHSRate && errors.GHSRate}
                          </small>
                        </div>

                      </div>

                      <div className="col-lg-6">
                              {/* Ngn rate */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="NGNRate" style={{color: 'rgb(44, 58, 80)' }}>NGN Rate</label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="NGNRate"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.NGNRate}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.NGNRate && errors.NGNRate}
                          </small>
                      </div>

                      </div>

                    </div>  

                    <button
                      type="submit"
                      disabled={isSaveDisabled}
                      className="btn btn-login_submit btn-block mt-4"
                    >
                      Add
                    </button>
                    <small>
                      <i
                        className="mdi mdi-information mr-1"
                        style={{ color: "#FF8400" }}
                      ></i>
                      The coin name entered is what is displayed to the user.
                    </small>
                  </Form>
                )}
              </Formik>


                  </div>

                  <div className='col-lg-7'>


                  <div className="mt-4">
                  <DataTable
                    title="Added Rates"
                    columns={columns}
                    data={addedRate}
                    pagination
                    persistTableHead
                    progressPending={false}
                  />
                  </div>

                  <div>
                  <button
                      type="submit"
                      className="btn btn-login_submit btn-block mt-4"
                      onClick={handleUpdateRate}
                      disabled={addedRate.length === 0 || isSaveDisabled}
                    >
                      Update Rate
                    </button>
                  </div>

                  </div>
                </div>

             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
   const id = ownProps.match.params.id;
   const rates = state.rates.rates;
   const rate = rates.find((val) => val.id === id);
   const currencyRate = rates.find((val) => val.id === id).localCurrencyRate
  return {
    rate,
    id,
    currencyRate
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    update: (creds) => dispatch(updateRate(creds)),
  };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EditRates);
import { GetApi, PostApi } from "../request";
import cogoToast from "cogo-toast";
import axios from "axios";
import { apiUrl } from "../config";


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// get trades on the platform
export const getTrades = () => {
  return async (dispatch, getState) => {
     dispatch({ type: "startTradeLoad" });
    try {
      const res = await GetApi("trades", getToken());
      if (res.status === 200) {
         dispatch({ type: "stopTradeLoad" });
        dispatch({ type: "Trades", data: res.data.findAllTrades });
      }
      if (res.status === 400) {
         dispatch({ type: "stopTradeLoad" });
        dispatch({ type: "Trade_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// filter trades by status,date and amount
export const filterTradesByValue = (val) => {
  var status = val.status;
  var from_date = val.from_date;
  var to_date = val.to_date;
  return async (dispatch, getState) => {
    dispatch({ type: "startTradeLoad" });
    try {
      const res = await GetApi(
        `trades?status=${status}&from_date=${from_date}&to_date=${to_date}`,
        getToken()
      );
      if (res.status === 200) {
         dispatch({ type: "stopTradeLoad" });
        dispatch({ type: "Trades", data: res.data.findAllTrades });
      }
      if (res.status === 400) {
         dispatch({ type: "stopTradeLoad" });
        dispatch({ type: "Trade_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// Search trades by amount or  cointype
export const searchTrade = (val) =>{
  return dispatch =>{
      dispatch({type: 'searchTrade', data: val})
  }
}


// filter trades by id
export const filterTrades = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "filterTrades", data: id });
  };
};

// resolve a trade functionality
export const resolveTrade = (val) => {
   const id = val.tradeId;
  return async (dispatch, getState) => {
    dispatch({ type: "startLoad" });
    try {
      const res = await PostApi(
        "resolve/trade",
        {
          ...val,
        },
        getToken(),
        "application/json"
      );
      if (res.status === 200) {

        cogoToast.success("Trade resolved successfully!");

        // make a call to get trade by id after resolving
         axios
           .get(apiUrl + "trades/"+id, {
             headers: {
               Accept: "application/json",
               Authorization: getToken(),
             },
           })
           .then((res) => {
             if (res.status === 200) {
               dispatch({ type: "stopLoad" });
               dispatch({
                 type: "Trade",
                 data: res.data.findTradeById,
               });
             }
           })
           .catch((err) => {
             dispatch({ type: "Trade_Error", data: err });
             dispatch({ type: "stopLoad" });
           });
      }
      if(res.status === 400 ){
        dispatch({ type: "stopLoad"});
        cogoToast.error("Oops, error while resolving trade!");
      }
    } catch (err) {
      console.log(err)
    }
  };
};

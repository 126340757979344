import React,{useEffect} from "react";
import UserSideBar from "../../components/Sidebar/sidebar";
import { Form, Formik } from "formik";
import { settingsValidator } from "../../validationSchema/validator";
import {connect} from 'react-redux'
import { getWithdrawSetings, toogleAutomaticCheck, updateWithdrawSettings } from "../../store/actions/admin";
import Logo from '../../assets/images/Favicon.png'


const WithdrawSettings = ({
  minimumAmount,
  maximumAmount,
  cedisToDollarRate,
  nairaToDollarRate,
  toggleCheck,
  toggleHandle,
  updateSettings,
  loader,
  configFetch,
  config,
  role,
}) => {
  // make call to fetch all settings
  useEffect(() => {
    configFetch();
  }, [configFetch]);

  const handleToggle = (e) => {
    const val = e.target.checked;
    toggleHandle(val);
  };

  const handleSubmit = async (values, setSubmitting) => {
    const creds = {
      ...values,
      toggleCheck,
    };
    await updateSettings(creds);
  };

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          <div style={{ position: "relative", minHeight: "100vh" }}>
            {loader ? (
              <div className="preloader">
                <img src={Logo} alt="logo" />
              </div>
            ) : (
              <div className="admin-card mt-5 mb-5">
                <div className="admin-div">
                  <div>
                    <h4
                      className="text-center"
                      style={{ color: "#2D2D2D", fontWeight: "bold" }}
                    >
                      Withdrawal Settings
                    </h4>
                  </div>

                  {/* form submission */}
                  <Formik
                    onSubmit={(values, { setSubmitting }) =>
                      handleSubmit(values, setSubmitting)
                    }
                    validationSchema={settingsValidator}
                    initialValues={{
                      minAmount:
                        config && config.hasOwnProperty("minimumWithdrawer")
                          ? minimumAmount
                          : 0,
                      maxAmount:
                        config && config.hasOwnProperty("maximumWithdrawer")
                          ? maximumAmount
                          : 0,
                      nairaToDollarRate:
                          config && config.hasOwnProperty("nairaToDollarRate")
                          ? nairaToDollarRate
                          : 0,
                      cedisToDollarRate:
                          config && config.hasOwnProperty("cedisToDollarRate")
                          ? cedisToDollarRate
                          : 0,

                    }}
                  >
                    {({
                      handleChange,
                      isSubmitting,
                      handleSubmit,
                      handleBlur,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form onSubmit={handleSubmit}>

                        {/* Enable automatic withdrawal */}
                        <div className="form-group input-container mt-4">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitch1"
                              checked={toggleCheck}
                              onChange={(e) => handleToggle(e)}
                            />
                            <label
                              className="custom-control-label"
                              style={{ fontSize: 15 }}
                              htmlFor="customSwitch1"
                            >
                              Enable automatic withdrawal
                            </label>
                          </div>
                         </div>
                                  
                        {/* max amount */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="email" style={{color: 'rgb(44, 58, 80)'}}>
                            Maximum Withdrawal Amount (USD)
                          </label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="maxAmount"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.maxAmount}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.maxAmount && errors.maxAmount}
                          </small>
                        </div>

                        {/* min amount */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="email" style={{color: 'rgb(44, 58, 80)'}}>
                            Minimum Withdrawal Amount (USD)
                          </label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="minAmount"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.minAmount}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.minAmount && errors.minAmount}
                          </small>
                        </div>

                      {/* Naira to Dollar VALUE */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="nairaToDollarRate" style={{color: 'rgb(44, 58, 80)'}}>
                           Naira to Dollar Rate
                          </label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="nairaToDollarRate"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.nairaToDollarRate}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.nairaToDollarRate && errors.nairaToDollarRate}
                          </small>
                        </div>

                          {/* Cedis to Dollar value */}
                        <div className="form-group input-container mt-3">
                          <label htmlFor="cedisToDollarRate" style={{color: 'rgb(44, 58, 80)'}}>
                            Cedis to Dollar Rate
                          </label>
                          <input
                            className="form-control input-style"
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="cedisToDollarRate"
                            style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                            value={values.cedisToDollarRate}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.cedisToDollarRate && errors.cedisToDollarRate}
                          </small>
                        </div>

                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            disabled={isSubmitting || role === "SubAdmin"}
                            className="btn btn-login_submit"
                            style={{ width: "auto" }}
                          >
                            Update Settings
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    minimumAmount: state.admin.config.minimumWithdrawer,
    maximumAmount: state.admin.config.maximumWithdrawer,
    nairaToDollarRate: state.admin.config.nairaToDollarRate,
    cedisToDollarRate: state.admin.config.cedisToDollarRate,
    toggleCheck: state.admin.enableAutomatedWithdrawer,
    loader: state.admin.loader,
    config: state.admin.config,
    role: state.auth.role,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleHandle: (val) => dispatch(toogleAutomaticCheck(val)),
    configFetch: () => dispatch(getWithdrawSetings()),
    updateSettings: (creds) => dispatch(updateWithdrawSettings(creds)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawSettings);

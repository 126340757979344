import React, {useEffect, useState} from "react";
import UserSideBar from "../../components/Sidebar/sidebar";
import "../../styles/dashboard.css";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { deleteRate, getRates } from "../../store/actions/rates";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Rates = ({ ratesFetch, rates, rateDelete }) => {
  const [initialTab, setTab] = useState(2);

  const history = useHistory();

  const [tabData] = useState([
    { id: 1, name: "tab-1", text: "New Rates" },
    { id: 2, name: "tab-2", text: "View Rates" },
  ]);

  // tab Layout
  const tabLayout = tabData.map((item) => (
    <div
      key={item.id}
      className={
        initialTab === item.id ? "activeOrderTab tabSpace" : "orderTab tabSpace"
      }
      onClick={() => handleToggle(item.id)}
    >
      <p className="mb-0 text-center">{item.text}</p>
    </div>
  ));

  const handleToggle = (id) => {
    setTab(id);
    if (id === 1) {
      history.push("/rates/add");
    } else {
      history.push("/rates/all");
    }
  };

  // make call to fetch rates
  useEffect(() => {
    ratesFetch();
  }, [ratesFetch]);

  const columns = [
    {
      name: "Coin Name",
      selector: (row) => row.coinType,
    },
    {
      name: "USD Rate",
      cell: (row) => <span>{`USD ${row.usdRateCoin}`}</span>,
    },
    {
      name: "Date Added",
      cell: (row) => (
        <span>
          <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
        </span>
      ),
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            ViewRequest(row.id);
          }}
        >
          View
        </button>
      ),
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            DeleteDialog(row.id);
          }}
        >
          Delete
        </button>
      ),
    },
  ];

  const DeleteDialog = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `You are about to delete this rate, Do you wish to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => ApproveDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const ApproveDelete = (id) => {
    rateDelete(id)
  };

  const ViewRequest = (id) => {
    history.push("/rates/edit/" + id);
  };

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          {/* order tabs select */}
          <div
            className="mt-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {tabLayout}
          </div>

          {/* datatable to show recent Transactions */}
          <div className="transactions-container mt-5 mb-5">
            <DataTable
              title="Rates"
              columns={columns}
              data={rates}
              pagination
              persistTableHead
              progressPending={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rates: state.rates.rates
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ratesFetch: () => dispatch(getRates()),
    rateDelete: (id) => dispatch(deleteRate(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Rates);

import { GetApi, PutApi, PostApi  } from "../request";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


// get all admins on the platform
export const getAllAdmins = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi("users?role=" + val, getToken());
      if (res.status === 200) {
        dispatch({ type: "Admins", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "Admin_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// suspend admin functionality
  export const SuspendAdmin = (user) =>{
    return async (dispatch, getState) => {
      // dispatch({ type: "Suspend_Loader", });
      let id  = user.userId
      let name = 'Suspend'
      try {
        const res = await PostApi("disableuser", { ...user }, getToken());
        if (res.status === 200) {
            // dispatch({ type: "StopSuspendLoader" });
            dispatch({type: "AdminSuccess", id, name})
          cogoToast.success('Admin successfully suspended!', { position: 'top-center', })
        }
        if(res.status === 400){
          // dispatch({ type: "StopSuspendLoader" });
          cogoToast.error('Error while suspending admin')
        }
      } catch (err) {
        // var message = err.response.data
        console.log(err)
      }
    };
  }

  // activate admin functionality
  export const ActivateAdmin = (user) =>{
    return async (dispatch, getState) => {
      // dispatch({ type: "Suspend_Loader", });
      let id  = user.userId
      let name = 'Restore'
      try {
        const res = await PostApi("enableuser", { ...user }, getToken());
        if (res.status === 200) {
            
            // dispatch({ type: "StopSuspendLoader" });
            dispatch({type: "AdminSuccess", id, name})
          cogoToast.success('Admin successfully activated!', { position: 'top-center', })
        }
        if(res.status === 400){
          // dispatch({ type: "StopSuspendLoader" });
          cogoToast.error('Error while activating admin')
        }
      } catch (err) {
       console.log(err)
      }
    }
  }


  // suspend user functionality
  export const SuspendUser = (user) =>{
    return async (dispatch, getState) => {
      // let id  = user.userId
      let name = 'Suspend'
      try {
        const res = await PostApi("disableuser", { ...user }, getToken());
        if (res.status === 200) {
            dispatch({type: "UserSuccess", name})
          cogoToast.success('User successfully suspended!', { position: 'top-center', })
        }
        if(res.status === 400){
          cogoToast.error('Error while suspending user')
        }
      } catch (err) {
        // var message = err.response.data
        console.log(err)
      }
    };
  }

  // activate user functionality
  export const ActivateUser = (user) =>{
    return async (dispatch, getState) => {
      let id  = user.userId
      let name = 'Restore'
      try {
        const res = await PostApi("enableuser", { ...user }, getToken());
        if (res.status === 200) {
            
            dispatch({type: "UserSuccess", id, name})
          cogoToast.success('User successfully activated!', { position: 'top-center', })
        }
        if(res.status === 400){
          cogoToast.error('Error while activating user')
        }
      } catch (err) {
       console.log(err)
      }
    }
  }


// get overview count on dashboard
export const getOverviewCount = () => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi("admin/dashboard/count", getToken());
      if (res.status === 200) {
        dispatch({ type: "OverviewCount", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "Count_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// get all users on the platform
export const getAllUsers = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi("users?role=" + val, getToken());
      if (res.status === 200) {
        dispatch({ type: "Users", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "User_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// get a user by id
export const getUserById = (id) => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi(`member/${id}`, getToken());
      if (res.status === 200) {
        dispatch({ type: "UserProfile", data: res.data.message.referralBonusUsers });
      }
      if (res.status === 400) {
        dispatch({ type: "UserProfile_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// get withdrawal settings
export const getWithdrawSetings = () => {
  return async (dispatch, getState) => {
     dispatch({ type: "StartLoader" });
    try {
      const res = await GetApi("configuration", getToken());
      if (res.status === 200) {
        dispatch({ type: "Config", data: res.data.message });
        dispatch({ type: "StopLoader" });
      }
      if (res.status === 400) {
        dispatch({ type: "Config_Error", err: res.data });
        dispatch({ type: "StopLoader" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// update withdrawal settings
export const updateWithdrawSettings = (val) => {
  return async (dispatch, getState) => {
    var id = getState().admin.config.id
    try {
      const res = await PutApi(
        "configuration/" + id,
        {
          minimumWithdrawer: parseFloat(val.minAmount),
          maximumWithdrawer: parseFloat(val.maxAmount),
          enableAutomatedWithdrawer: val.toggleCheck,
        },
        getToken(),
        "application/json"
      );
      if (res.status === 201) {
        dispatch({ type: "Config_Update" });
        cogoToast.success("Configuration updated successfully!");
      }
      if (res.status === 400) {
        dispatch({ type: "Config_Fail", err: res.data.message });
        cogoToast.error("Oops an error occured!");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// enable and disable automatic check
export const toogleAutomaticCheck = (val) => {
  return (dispatch, getState) => {
    dispatch({ type: "handleToggle", data: val });
  };
};

// filter users by id
export const filterUsers = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "filterUsers", data: id });
  };
};

// Search a user by name or email
export const searchUser = (val) => {
  return (dispatch) => {
    dispatch({ type: "searchUser", data: val });
  };
};

// get kyc settings
export const getKYCSettings = () => {
  return async (dispatch, getState) => {
     dispatch({ type: "StartLoader" });
    try {
      const res = await GetApi("kyc/admin", getToken());
      if (res.status === 200) {
        dispatch({ type: "KYCSettings", data: res.data.message });
        dispatch({ type: "StopLoader" });
      }
      if (res.status === 400) {
        dispatch({ type: "KYCSettings_Error", err: res.data });
        dispatch({ type: "StopLoader" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// update kyc settings
export const updateKYCSettings = (val) => {
  return async (dispatch) => {
    try {
      const res = await PutApi(
        "kyc/admin/" + val.id,
        {
          description: val.description,
          dailyWithdrawerLimit: parseFloat(val.dailyWithdrawerLimit),
          cummulativeWithdrawerLimit: parseFloat(val.cummulativeWithdrawerLimit),
        },
        getToken(),
        "application/json"
      );
      if (res.status === 200) {
        dispatch({ type: "KYC_Update" });
        cogoToast.success("KYC settings updated successfully!");
      }
      if (res.status === 400) {
        cogoToast.error("Oops an error occured!");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

import React,{useEffect, useState} from "react";
import "../../styles/login.css";
import Logo from "../../assets/logo/logo-white.svg";
import { Link, useHistory } from "react-router-dom";
// import Woman from "../../assets/images/woman.png";
import world from "../../assets/images/world.svg";
import { Form, Formik } from "formik";
import { loginValidator } from "../../validationSchema/validator";
import { connect } from "react-redux";
import { loginUser } from "../../store/actions/auth";
import eye from '../../assets/logo/eye.svg'
import eye_off from '../../assets/logo/eye-slash.svg'

const LoginPage = ({ login, isAuthenticated, isAuthSecret }) => {

  const history = useHistory();

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

  // login func
  const handleSubmit = async (values) => {
    await login(values)
  };

    useEffect(() => {
      if (isAuthenticated) {
        if(isAuthSecret){
          history.push("/2fa/login");
        }
        else{
          history.push("/2fa-setup");
        }       
      }
    }, [isAuthSecret, isAuthenticated, history]);


  return (
    <>
      <div className="login-container">
        {/* first half */}
        <div className="first_half">
          <div className="first_half_container">
            {/* logo */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link to="/">
                <img
                  src={Logo}
                  style={{ height: "3rem" }}
                  className="img-fluid"
                  alt="Dart Logo"
                />
              </Link>
            </div>

            <div className="login-title">
              <h2>Sign In</h2>
              <h6 className="mt-2">With your DartAfrica Admin credentials</h6>
            </div>

            {/* form submission */}
            <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={loginValidator}
              initialValues={{ email: "", password: "" }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className="mt-5 form-container">
                    {/* email address */}
                    <div className="form-group input-container mb-3">
                      <label htmlFor="email">Email Address</label>
                      <input
                        className="form-control input-style"
                        type="email"
                        placeholder="Your Email"
                        id="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.email && errors.email}
                      </small>
                    </div>

                    {/* password */}
                    <div className="form-group input-container mb-2">
                      <label htmlFor="password">Password</label>

                      <div style={{ position: "relative" }}>
                        <input
                          className="form-control input-style"
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          id="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <img
                         className="toggle-style"
                         alt="eye"
                          onClick={togglePasswordVisiblity}
                          src={passwordShown ? eye_off : eye}
                        />
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {touched.password && errors.password}
                      </small>
                    </div>

                    <Link
                      to="/forgotpassword"
                      className="text-right"
                      style={{ textDecoration: "none" }}
                    >
                      <p className="forgot-text">Forgot Password?</p>
                    </Link>

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-login_submit"
                      >
                        Sign In
                      </button>
                    </div>

                    <div className="copyright text-center">
                      <p className="mb-0">
                        &copy; {new Date().getFullYear()} DartAfrica All rights
                        reserved.
                      </p>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* second half */}
        <div className="second_half">
         <img src={world} className="img-fluid" alt="sell bitcoins" />
      
          <div className='login_text'>
                <h4>Unlock a World of <br/> Endless Possibilities.</h4>
            </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthSecret: state.auth.isAuthSecret,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    login: (creds) => dispatch(loginUser(creds)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

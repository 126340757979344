import React from "react";
import "../../styles/notfound.css";
import mail from "../../assets/images/mail.svg";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/Logo.svg";

const NotFoundPage = () => {
  return (
    <>
      <div className="contain">
        <div className="verify-container">
          {/* logo */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <img
                src={Logo}
                style={{ height: "3rem" }}
                className="img-fluid"
                alt="Dart Logo"
              />
            </Link>
            
          </div>

          <div className="not-found-contain">
            <div className="not-found-div">
              <h3 className="text-center">404</h3>
              <p className="text-center mt-2">
                Sorry we are unable to find that page
              </p>
              <div className="text-center">
                <img src={mail} className="not-found-icon" alt="feature icon" />
              </div>
              <div className="not-found-link text-center mt-4">
                <Link to="/" className="">
                   Return to Login
                </Link>
              </div>
            </div>
          </div>

          {/* copyright */}
          <div
            className="copyright text-center"
            style={{ color: "#474747", fontSize: 14, marginTop: "50px" }}
          >
            <p className="mb-0">
              &copy; {new Date().getFullYear()} DartAfrica All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;

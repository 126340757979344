import React from "react";
import UserSideBar from "../../components/Sidebar/sidebar";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { declineRequest, flutterWavePayment, manualRequest } from "../../store/actions/request";


const WithdrawalDetails = ({
  request,
  decline,
  manual,
  loader,
  flutterPay,
}) => {
  const getStatusColor = (val) => {
    let result;
    switch (val) {
      case "Pending":
        result = "pending-trade-color";
        break;
      case "Processing":
        result = "pending-trade-color";
        break;
      case "Completed":
        result = "completed-trade-color";
        break;
      case "Failed":
        result = "failed-trade-color";
        break;
      default:
        result = "failed-trade-color";
        break;
    }
    return result;
  };

  const Decline = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `You are about to decline this request, Do you wish to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDecline(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const confirmDecline = (id) => {
    decline(id);
  };

  const Approve = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `How would you like to approve this transaction?`,
      buttons: [
        {
          label: "Manual Success",
          onClick: () => ManualApprove(id),
        },
        {
          label: "Flutterwave Approve",
          onClick: () => FlutterwavePay(id),
        },
      ],
    });
  };

  const ManualApprove = (id) => {
    manual(id);
  };

  const FlutterwavePay = (id) => {
    flutterPay(id)
  };

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <Link
                to="/requests"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#FF8400",
                  textDecoration: "none",
                }}
              >
                <i
                  className="mdi mdi-chevron-left mr-1"
                  style={{ fontSize: 20 }}
                ></i>
                <p className="mb-0" style={{ fontSize: 14 }}>
                  Go Back
                </p>
              </Link>

              <div>
                <h4
                  className="text-center mt-4 mt-md-2"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Withdrawal Details
                </h4>
              </div>

              {/* details */}
              <div className="row mt-md-5 mt-4">
                {/* username and time */}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Username
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {request ? request.userDetails.username : "Ade"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Date Requested
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {request ? (
                        <Moment format="MMMM Do, YYYY, h:mm:ss a">
                          {request.createdAt}
                        </Moment>
                      ) : (
                        "Trade Date"
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Amount
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {request.userDetails.countryTag === "GH" ? "GHS": "NGN"} {request ? `${request.amount}` : "0"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Account Type
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {request ? `${request.accountType}` : "Type"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      {request.accountType === "Bank"
                        ? "Account Number"
                        : "Mobile Number"}
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {request.accountType === "Bank"
                        ? request.accountNumber
                        : request.mobileNumber}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Account Name
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {request.accountType === "Bank"
                        ? request.accountName
                        : request.accountName}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      {request.accountType === "Bank"
                        ? "Bank Name"
                        : "Mobile Network"}
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {request.accountType === "Bank"
                        ? request.bankName
                        : request.mobileNetwork}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-3">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Narration
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {request && request.hasOwnProperty("narration")
                        ? `${request.narration}`
                        : ""}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Status
                    </p>
                    <div className="mt-2" style={{ display: "flex" }}>
                      <p
                        className={getStatusColor(
                          request ? request.status : "Failed"
                        )}
                        style={{ fontSize: 14 }}
                      >
                        {request ? request.status : "Status"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {request.status !== "Pending" ? (
                ""
              ) : (
                <div className="mt-5 withdraw-div">
                  <button
                    type="submit"
                    className="btn btn-login_submit mr-0 mr-lg-4"
                    style={{ width: "auto" }}
                    disabled={loader}
                    onClick={() => {
                      Approve(request.id);
                    }}
                  >
                    Approve Request
                  </button>

                  <button
                    type="submit"
                    className="btn btn-suspend_submit mt-3 mt-lg-0"
                    style={{ width: "auto" }}
                    disabled={loader}
                    onClick={() => {
                      Decline(request.id);
                    }}
                  >
                    Decline Request
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    request: state.request.request,
    loader: state.request.loader,  
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    decline: (id) => dispatch(declineRequest(id)),
    manual: (id) => dispatch(manualRequest(id)),
    flutterPay: (id) => dispatch(flutterWavePayment(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalDetails);

import { GetApi  } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


// get admin analytics 
export const getAdminAnalytics = () => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi("admin/analytics/alltime", getToken());
      if (res.status === 200) {
        dispatch({ type: "Analytics", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "Analytics_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// get users analytics with filters
export const getAdminAnalyticsWithFilter = (type, from_date, to_date) => {
    return async (dispatch, getState) => {
        dispatch({type: 'Loader'})
      try {
        const res = await GetApi(`admin/analytics/filter/${type}?from_date=${from_date}&to_date=${to_date}`, getToken());
        if (res.status === 200) {
          dispatch({ type: "UserAnalytics", data: res.data.message });
        }
        if (res.status === 400 || 404) {
          dispatch({ type: "UserAnalytics_Error", err: res.data });
        }
      } catch (err) {
        console.log(err);
      }
    };
  };

  // Search a user by name
export const searchUser = (val) => {
    return (dispatch) => {
      dispatch({ type: "searchUser", data: val });
    };
  };
import React,{useEffect} from 'react';
import "../../styles/login.css";
import Logo from "../../assets/logo/Logo.svg";
import { Link } from "react-router-dom";
import world from "../../assets/images/world.svg";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cogoToast from 'cogo-toast';
import copycontent from "../../assets/images/content-copy.svg";
import { Form, Formik } from "formik";
import { twofaCodeValidator } from "../../validationSchema/validator";
import { connect } from 'react-redux';
import { generateAuthSecret, validateAuthSecret } from '../../store/actions/auth';
import { useHistory } from 'react-router-dom';

const TwoAuthSetupPage = ({secretInfo, generateSecret, validcode, validateCode}) => {

    const history = useHistory();

    const handleCopy = () => {
        cogoToast.success("Copied to clipboard");
      };

    useEffect(()=>{
        generateSecret()
    },[generateSecret])
    
    const handleSubmit = async (val) =>{
        await validateCode(val)
    }

    useEffect(() => {
        if (validcode) {
            history.push("/dashboard");    
        }
      }, [validcode, history]);

    return ( 
        <>
         <div className="login-container">
        {/* first half */}
        <div className="first_half">
          <div className="first_half_container">
            {/* logo */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link to="/">
                <img
                  src={Logo}
                  style={{ height: "3rem" }}
                  className="img-fluid"
                  alt="Dart Logo"
                />
              </Link>
            </div>

            <div className="login-title">
              <h2>Two Factor Setup</h2>
              <h6 className="mt-2">Take these few steps to enable two-factor authentication and make your account more secure.</h6>
            </div>

            <div className='mt-4 twofa_div'>
                <div>
                  <QRCode
                    title="Bar Code"
                    size="220"
                    value={ secretInfo.hasOwnProperty('otpauth_url') ? secretInfo.otpauth_url : 'otpauth://totp/SecretKey?secret=LNWVQ22DOBHUKY3MIJFEUKB6Idemosecretkey'}
                  />
                </div>
                <div className='mt-4 mt-lg-0'>
                    <ul>
                        <li className='mb-3'>
                            Install the authenticator app on your mobile device. Recommened options - Google Authenticator 
                        </li>
                        <li className='mb-3'>
                            Scan QR Code with the authenticator app or enter the secret key manually, Store this secret key somewhere safe, print it, write it down or save a screenshot.
                        </li>
                        <li>
                            Enter the 2-step authentication code provided by your authenticator app
                        </li>
                        
                    </ul>

                </div>
            </div>

            <div className="form-group input-container mt-3">
            <label htmlFor="code">Secret Key</label>
                <div className='qr-input-container'>
                    <div className="copy-content">
                        <CopyToClipboard
                            text={secretInfo.hasOwnProperty('base32') ? secretInfo.base32 : 'LNWVQ22DOBHUKY3MIJFEUKB6Idemosecretkey'}
                            onCopy={() => handleCopy()}
                        >
                            <img
                            src={copycontent}
                            width="20"
                            height="20"
                            alt="copy"
                            />
                        </CopyToClipboard>
                    </div>
                   
                    <input
                      id="amount"
                      className="form-control qr-input-style"
                      placeholder="Secret Key"
                      type="text"
                      disabled
                      value={secretInfo.hasOwnProperty('base32') ? secretInfo.base32 : 'LNWVQ22DOBHUKY3MIJFEUKB6Idemosecretkey'}
                    />
                </div>
                
            </div>

            <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={twofaCodeValidator}
              initialValues={{ code: "",}}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className="mt-4 form-container">
                    {/* email address */}
                    <div className="form-group input-container mb-3">
                      <label htmlFor="code">Authentication Code</label>
                      <input
                        className="form-control input-style"
                        type="text"
                        placeholder="Enter the 6 digits code here"
                        id="code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.code}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.code && errors.code}
                      </small>
                    </div>

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-login_submit"
                      >
                        Enable
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>



          
          </div>
        </div>
        {/* second half */}
        <div className="second_half">
           <img src={world} className="img-fluid" alt="sell bitcoins" />
      
          <div className='login_text'>
                <h4>Unlock a World of <br/> Endless Possibilities.</h4>
            </div>
        </div>
      </div>
        </>
     );
}



const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      secretInfo: state.auth.authsecretinfo,
      validcode: state.auth.validauthcode,
    };
  };
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
      generateSecret: () => dispatch(generateAuthSecret()),
      validateCode: (code) => dispatch(validateAuthSecret(code)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(TwoAuthSetupPage);
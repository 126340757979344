import { GetApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// get transactions on the platform
export const getTransactions = () => {
  return async (dispatch, getState) => {
      dispatch({ type: "LoadTransactions" });
    try {
      const res = await GetApi("transactions/admin", getToken());
      if (res.status === 200) {
         dispatch({ type: "LoadStop" });
        dispatch({ type: "Transactions", data: res.data.message });
      }
      if (res.status === 400) {
         dispatch({ type: "LoadStop" });
        dispatch({ type: "Transact_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// filter trades by id
export const filterTransactionDetails = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "filterTransactions", data: id });
  };
};

// filter transactions by date,status and amount
export const filterTransactions = (val) => {
  var status = val.status;
  var from_date = val.from_date;
  var to_date = val.to_date;
  return async (dispatch, getState) => {
    dispatch({ type: "LoadTransactions" });
    try {
      const res = await GetApi(
        `transactions/admin?status=${status}&from_date=${from_date}&to_date=${to_date}`,
        getToken()
      );
      if (res.status === 200) {
        dispatch({ type: "LoadStop" });
        dispatch({ type: "Transactions", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "LoadStop" });
        dispatch({ type: "Transact_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// get all users transactions on the platform
export const getUserTransactions = (id) => {
  return async (dispatch, getState) => {
     dispatch({ type: "LoadTransactions" });
    try {
      const res = await GetApi("user/transactions/"+id, getToken());
      if (res.status === 200) {
        dispatch({ type: "LoadStop" });
        dispatch({ type: "Transactions", data: res.data.message });
      }
      if (res.status === 400) {
         dispatch({ type: "LoadStop" });
        dispatch({ type: "Transact_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// filter by reference or transaction id
export const filterTransactionID = (val) => {
  return (dispatch) => {
    dispatch({ type: "filterTransactionID", data: val });
  };
};

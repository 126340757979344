import React from "react";
import "../../styles/verify.css";
import Logo from "../../assets/logo/logo-white.svg";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import { twofaCodeValidator } from "../../validationSchema/validator";
import { connect } from "react-redux";
import { verifyEmail } from "../../store/actions/auth";

const VerifyInfo = ({verify}) => {
  
  const handleSendAgain = () => {
    alert("link sent!");
  };

  const handleSubmit =  async (values) => {
    console.log(values)
    await verify(values)
  }

  return (
    <>
      <div className="contain" style={{ background :'#1B1A20', minHeight: '100vh'}}>
        <div className="verify-container">
          {/* logo */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <img
                src={Logo}
                style={{ height: "3rem" }}
                className="img-fluid"
                alt="Dart Logo"
              />
            </Link>
           
          </div>

          {/* info display layout */}
          <div className="info-contain">
            <div className="info-div">
              <h3 className="text-center">Verify your account</h3>
              <p className="text-center">
                Account activation link has been sent to the e-mail address you
                provided
              </p>

              <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={twofaCodeValidator}
              initialValues={{ code: "" }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className="mt-4 form-container">
                    {/* email address */}
                    <div className="form-group input-container mb-3">
                      <label htmlFor="code">Verification Code</label>
                      <input
                        className="form-control input-style"
                        type="text"
                        placeholder="Enter Verification Code"
                        id="code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.code}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.code && errors.code}
                      </small>
                    </div>

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-login_submit"
                      >
                       Verify
                      </button>
                    </div>

                  </div>
                </Form>
              )}
            </Formik>

              <div className="verify-link text-center mt-4">
                Didn't get the mail?{" "}
                <Link onClick={handleSendAgain} className="">
                  Send it again
                </Link>
              </div>
            </div>
          </div>

          {/* copyright */}
          <div
            className="copyright text-center"
            style={{ color: "#F1F5F6", fontSize: 14, marginTop: "50px" }}
          >
            <p className="mb-0">
              &copy; {new Date().getFullYear()} DartAfrica All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    verify: (creds) => dispatch(verifyEmail(creds)),
  };
};


export default connect(null, mapDispatchToProps)(VerifyInfo);


import React, { useEffect, useState, useCallback } from "react";
import "../../styles/sidebar.css";
import Logo from "../../assets/logo/Logo_white.svg";
import profile from "../../assets/images/user.svg";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../../store/actions/auth";

const UserSideBar = (props) => {
  const [sideShow, setSideShow] = useState(false);

  const { Logout, username, imageUrl,userRole } = props;

  const ToggleLogout = () => {
    Logout();
  };

  useEffect(() => {
    document.body.classList.add("barBg");
  }, []);

  const closeSideBar = useCallback(() => {
    setSideShow(sideShow ? false : true);
  }, [sideShow]);

  useEffect(() => {
    if (sideShow) {
      document.body.addEventListener("click", closeSideBar);
    

      return () => document.body.removeEventListener("click", closeSideBar);
    }
  }, [closeSideBar, sideShow]);

  // check for which path you are on
  const isActive = useLocation().pathname;

  const ToggleSidebar = () => {
    setSideShow(sideShow ? false : true);
  };

  const TitleFormat = () => {
    let val;
    switch (isActive) {
      case "/requests":
        val = "Withdrawal Requests";
        break;
      case "/dashboard":
        val = "Overview";
        break;
      case "/transactions":
        val = "Transactions";
        break;
      case "/users":
        val = "Users";
        break;
      case "/trades":
        val = "Trades";
        break;
      case "/rates/all":
        val = "Rates";
        break;
      case "/rates/add":
        val = "Rates";
        break;
      case "/admin":
        val = "Admin";
        break;
      case "/admin/all":
        val = "Admin";
        break;
      case "/profile":
        val = "Profile";
        break;
      case "/settings":
        val = "Settings";
        break;
        case "/kyc-settings":
        val = "KYC Settings";
        break;
      case "/analytics":
        val = "Analytics";
        break;
      case "/campaign":
        val = "Push Notification"
        break;
        case "/direct-debit":
          val = "Direct Debit/Credit"
          break;
      default:
        val = "Details";
        break;
    }
    return val;
  };

  return (
    <>
      {/* sidebar */}
      <div>
        <div className={sideShow ? "userside show" : "userside"}>
          {/* logo */}
          <div className="text-center mt-3">
            <img src={Logo} alt="logo" height="50" />
          </div>

          {/* nav list */}
          <div className="v-list mt-4">
            {/* first */}
            <Link
              to="/dashboard"
              className={
                isActive.includes("/dashboard")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/dashboard")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-chart-pie"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/dashboard") ? "active" : ""}
                >
                  Overview
                </span>
              </div>
            </Link>

            {/* second */}
            <Link
              to="/transactions"
              className={
                isActive.includes("/transaction")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/transaction")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-credit-card"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/transaction") ? "active" : ""}
                >
                  Transactions
                </span>
              </div>
            </Link>

            <Link
              to="/trades"
              className={
                isActive.includes("/trade")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/trade")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-coins"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span className={isActive.includes("/trade") ? "active" : ""}>
                  Trades
                </span>
              </div>
            </Link>

            {/* third */}
            <Link
              to="/requests"
              className={
                isActive.includes("/request")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/request")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-hand-pointer"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span className={isActive.includes("/request") ? "active" : ""}>
                  Withdrawal Requests
                </span>
              </div>
            </Link>

            {/* fourth */}
            <Link
              to="/users"
              className={
                isActive.includes("/user")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/user")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-user"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span className={isActive.includes("/user") ? "active" : ""}>
                  Users
                </span>
              </div>
            </Link>

              {/* analytics  */}
            <Link
              to="/analytics"
              className={
                isActive.includes("/analytics")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/analytics")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-user-tie"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span className={isActive.includes("/analytics") ? "active" : ""}>
                  Analytics
                </span>
              </div>
            </Link>

            {/* fifthe */}
            <Link
              to="/admin"
              className={
                isActive.includes("/admin")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/admin")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-user-tie"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span className={isActive.includes("/admin") ? "active" : ""}>
                  Admin
                </span>
              </div>
            </Link>

            {/* sixth */}
            <Link
              to="/rates/add"
              className={
                isActive.includes("/rates")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/rates")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-exchange"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span className={isActive.includes("/rates") ? "active" : ""}>
                  Rates
                </span>
              </div>
            </Link>

            {/* seventh */}
            <Link
              to="/profile"
              className={
                isActive.includes("/profile")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/profile")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-user"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span className={isActive.includes("/profile") ? "active" : ""}>
                  Profile
                </span>
              </div>
            </Link>

            {/* horozontal line */}
            <div className="mt-5">
              <hr style={{ borderTop: "1px solid #DFE0EB" }} />
            </div>

              {/* direct debit */}
           {userRole === "Admin" &&   
           <Link
              to="/direct-debit"
              className={
                isActive.includes("/direct-debit")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/direct-debit")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-bell"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/direct-debit") ? "active" : ""}
                >
                  Direct Debit/Credit
                </span>
              </div>
            </Link>
              }

            {/* notifications campaign */}
            <Link
              to="/campaign"
              className={
                isActive.includes("/campaign")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/campaign")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-bell"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/campaign") ? "active" : ""}
                >
                  Create Campaign
                </span>
              </div>
            </Link>

            {/* settings */}
            <Link
              to="/settings"
              className={
                isActive.includes("/settings")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/settings")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-cog"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/settings") ? "active" : ""}
                >
                  Settings
                </span>
              </div>
            </Link>


            {/* KYC Settings */}
            <Link
              to="/kyc-settings"
              className={
                isActive.includes("/kyc-settings")
                  ? "v-list-item active v-list-link"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/kyc-settings")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                <i className="fa fa-cog"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/kyc-settings") ? "active" : ""}
                >
                  KYC Settings
                </span>
              </div>
            </Link>

            {/* logout */}
            <Link
              to="/"
              onClick={ToggleLogout}
              className="v-list-item v-list-link"
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div className="v-list-icon">
                <i className="fa fa-power-off"></i>
              </div>
              {/* title */}
              <div className="v-list-title">
                <span>Logout</span>
              </div>
            </Link>
          </div>
        </div>

        {/* navbar */}
        <nav>
          <div className="sidehead">
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* icon for hamburger */}
              <div
                onClick={ToggleSidebar}
                className="d-md-none d-sm-block"
                style={{ marginRight: "12px", cursor: "pointer" }}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 24 }}></i>
              </div>

              <div>
                <p
                  className="mb-0"
                  style={{ color: "#2C3A50", fontWeight: "normal" }}
                >
                  {TitleFormat()}
                </p>
              </div>

              <div style={{ flexGrow: 1 }}></div>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        color: "#252733",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      {username ? username : "Anonymous"}
                    </p>
                  </div>

                  <div className="ml-3">
                    <img
                      className="imageStyle"
                      src={imageUrl ? imageUrl : profile}
                      alt="user"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
    imageUrl: state.auth.imageUrl,
    userRole: state.auth.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Logout: () => dispatch(logOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSideBar);

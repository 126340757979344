import { combineReducers } from "redux";
import authReducer from "./auth";
import adminReducer from './admin'
import rateReducer from "./rates";
import transactionReducer from "./transactions";
import tradeReducer from "./trades";
import requestReducer from "./request";
import campaignReducer from "./campaign";
import analyticReducer from "./analytics";
import debitReducer from "./direct";

const appReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  rates: rateReducer,
  transactions: transactionReducer,
  trades: tradeReducer,
  request: requestReducer,
  campaign: campaignReducer,
  analytic: analyticReducer,
  debit: debitReducer
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

import React, {useEffect} from 'react';
import UserSideBar from "../../components/Sidebar/sidebar";
import { useFormik } from "formik";
import { pushNotificationvalidator } from "../../validationSchema/validator";
import {connect} from 'react-redux'
import { clearCampaign, createCampaign } from '../../store/actions/campaign';


const Campaign = ({ handleCampaign, loading, handleClear }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      title: "",
      content: "",
    },
    onSubmit(values, { setSubmitting }) {
      handSubmit(values, setSubmitting);
    },
    validationSchema: pushNotificationvalidator,
  });

  const handSubmit = async (values, setSubmitting) => {
    await handleCampaign(values);

    setTimeout(() => {
      setSubmitting(false);
    }, 2000);
  };

  useEffect(() => {
    if (loading) {
      setFieldValue("title", "");
      setFieldValue("content", "");
      setFieldTouched("content", false)
      setFieldTouched("title", false)
      setTimeout(() => {
       handleClear();
      }, 2000);
    }
  }, [loading, handleClear, setFieldValue, setFieldTouched]);

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <div>
                <h4
                  className="text-center"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Create Campaign
                </h4>
              </div>

              <form onSubmit={handleSubmit}>
                {/* title */}
                <div className="form-group input-container mt-4">
                  <label htmlFor="title" style={{color: 'rgb(44, 58, 80)'}}>Campaign Title</label>
                  <input
                    className="form-control input-style"
                    type="text"
                    placeholder="Title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="title"
                    style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                    value={values.title}
                  />
                  <small style={{ color: "#dc3545" }}>
                    {touched.title && errors.title}
                  </small>
                </div>

                {/* content */}
                <div className="form-group input-container mt-3">
                  <label htmlFor="content" style={{color: 'rgb(44, 58, 80)'}}>Campaign Content</label>
                  <textarea
                    rows="5"
                    className="form-control input-style"
                    id="content"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.content}
                    placeholder="Campaign Content"
               
                    style={{ width: "100%",color: 'rgb(44, 58, 80)', resize: 'none' }}
                  ></textarea>

                  <small style={{ color: "#dc3545" }}>
                    {touched.content && errors.content}
                  </small>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-login_submit btn-block mt-4"
                >
                  Create Campaign
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.campaign.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleCampaign: (creds) => dispatch(createCampaign(creds)),
    handleClear: () => dispatch(clearCampaign()),
  };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
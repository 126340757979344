const initState = {
    loading: false
  };
  
  const debitReducer = (state = initState, action) => {
    switch (action.type) {
      case "Debit_Success":
        return {
          ...state,
          loading: true,
        };
      case "Debit_Fail":
        return {
          ...state,
          loading: false
        }
     case "Credit_Success":
        return{
            ...state,
            loading: true
        }
    case "Credit_Fail":
        return{
            ...state,
            loading: false
        }
      case "clearDebit":
        return {
          ...state,
          loading: false
        }
      default:
        return state;
    }
  };
  
  export default debitReducer;
  
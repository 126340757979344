const initState = {
  loading: false
};

const campaignReducer = (state = initState, action) => {
  switch (action.type) {
    case "Campaign_Success":
      return {
        ...state,
        loading: true,
      };
    case "Campaign_Fail":
      return {
        ...state,
        loading: false
      }
    case "clearCampaign":
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
};

export default campaignReducer;

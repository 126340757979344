import {  PostApi } from "../request";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// add a new rate functionality
export const createCampaign = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        "notification",
        {
          title: val.title,
          body: val.content,
        },
        getToken(),
        "application/json"
      );
      if (res.status === 201) {
         dispatch({ type: "Campaign_Success" });
        cogoToast.success("Campaign message sent successfully!", {
          position: "bottom-right",
        });
      }
      if (res.status === 400) {
        dispatch({ type: "Campaign_Fail" });
        cogoToast.error("Error while sending campaign!");
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// reset campaign success
export const clearCampaign = () => {
  return (dispatch, getState) => {
    dispatch({ type: "clearCampaign" });
  };
};


const initState = {
  isAuthenticated: false,
  token: "",
  profile: {},
  role: "",
  username: "",
  imageUrl: "",
  photoloader: false,
  isAuthSecret: false,
  authsecretinfo: {},
  validauthcode: false
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      localStorage.setItem("token", action.data.token);
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        token: action.data.token,
        profile: action.data.profile,
        role: action.data.profile.role,
        imageUrl: action.data.profile.imageUrl,
        isAuthSecret: action.data.profile.isAuthSecret,
        username: action.data.profile.username,
        signupsuccess: false,
      };
    case 'AuthSecretInfo':
      return{
        ...state,
        authsecretinfo: action.data
      }
    case 'ValidAuthCode':
      return{
        ...state,
        validauthcode: true,
        authsecretinfo: {}
      }
    case "PhotoLoader":
      return {
        ...state,
        photoloader: true,
      };
    case "StopPhotoLoader":
      return {
        ...state,
        photoloader: false,
      };
    case "profilePicture":
      return {
        ...state,
        imageUrl: action.image,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        signupsuccess: true,
      };
    case "SIGNUP_FAIL":
      return {
        ...state,
        signupsuccess: false,
      };
    case "ClearSignUp":
      return {
        ...state,
        signupsuccess: false,
      };

    default:
      return state;
  }
};

export default authReducer;

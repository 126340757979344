import React,{useEffect, useState} from 'react'
import UserSideBar from "../../components/Sidebar/sidebar";
import "../../styles/dashboard.css";
import DataTable from "react-data-table-component";
import { connect } from 'react-redux'
import hamburger from "../../assets/images/align-left.svg";
import Modal from "../../components/ModalComponent/Modal";
import { Form, Formik } from "formik";
import { userValidator } from "../../validationSchema/validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAdminAnalytics, getAdminAnalyticsWithFilter, searchUser } from '../../store/actions/analytics';


const UserAnalyticsPage = ({ fetchAnalytics, analytics, fetchUserAnalytics, userData, loader, searchUsers }) => {



 const [search, setSearchValue] = useState("");
 const [showFilterModal, setFilterModal] = useState(false);
 const [startDate, setStartDate] = useState(new Date());
 const [endDate, setEndDate] = useState(new Date());

 const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

 const [fromDate, setFromDate] = useState(formatDate(new Date()));
 const [toDate, setToDate] = useState(formatDate(new Date()));

 const [category, setCategory] = useState('Balance')


      // make call to fetch analytics
  useEffect(() => {
    let type = 'Balance'
    let start = formatDate(new Date())
    let end = formatDate(new Date())
    fetchUserAnalytics(type, start, end)
    fetchAnalytics();
  }, [fetchAnalytics, fetchUserAnalytics]);

  
  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },
    {
      name: "Wallet Balance",
      cell: (row) => (
        <span>
            {`${row.country === "Ghana" ? 'GHS' : 'NGN'} ${row.walletBalance.toFixed(2)}`}
        </span>
      ),
    },
    {
      name: `${category === 'Inflow' ? 'Total Inflow' :'Total Withdrawn'}`,
      omit: category === 'Balance' ? true : false,
      cell: (row) => (
        <span>
            {`${row.country === "Ghana" ? 'GHS' : 'NGN'} ${row.totalAmountInLocalCurrency ? row.totalAmountInLocalCurrency: 0}`}
        </span>
      ),
    }
  ];

  // const ViewRequest = (id) => {
  //   alert(id)
  // };



  const numberWithCommas = (x) => { 
    return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    searchUsers(search);
  }, [search, searchUsers]);

 

  const handleSubmit = async (values) => {
    let res;
    res = {
      from_date: formatDate(startDate),
      to_date: formatDate(endDate),
      status: values.category,
    };
    setCategory(values.category)
    setFromDate(res.from_date)
    setToDate(res.to_date)
    setFilterModal(false);
    await fetchUserAnalytics(values.category, formatDate(startDate), formatDate(endDate))
  };


    return (  
        <>
        <UserSideBar />
           <div className="usermain">
                <div className="contain">

             {/* dialog for filter transactions */}
                  <Modal
            title="Filter Users"
            show={showFilterModal}
            onClose={() => setFilterModal(false)}
          >
            <div>
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={userValidator}
                initialValues={{
                  category: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* Date*/}
                    <div className="row mt-4">
                      <div className="col-lg-6">
                        <div className="form-group input-container">
                          <label htmlFor="startDate" style={{color: 'rgb(44, 58, 80)' }}>Start Date</label>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="form-control date-select-style"
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group input-container">
                          <label htmlFor="startDate" style={{color: 'rgb(44, 58, 80)' }}>End Date</label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className="form-control date-select-style"
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Category */}
                    <div className="form-group mt-lg-2 input-container">
                      <label htmlFor="category" style={{color: 'rgb(44, 58, 80)' }}>Category</label>
                      <select
                        name="category"
                        values={values.category}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control date-select-style"
                        id="category"
                      >
                        <option defaultValue="">--Select--</option>
                        <option value="Balance">Balance</option>
                        <option value="Inflow">Inflow</option>
                        <option value="Withdrawn">Withdrawn</option>
                      </select>
                      <small style={{ color: "#dc3545" }}>
                        {touched.category && errors.category}
                      </small>
                    </div>

                    <div className="text-center mt-4 ">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-login_submit"
                        style={{ fontWeight: "normal" }}
                      >
                        Filter
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
          {/* end of filter dialog */}

                    {/* total balance by users */}
                    <div className='row mt-5'>
                        <div className='col-lg-4 mb-4 mb-lg-0'>
                            <div className="dash-div">
                                    <div className="mt-1">
                                    <p>Total Balance by Users</p>
                                    </div>

                                    <div className="mt-3">
                                        <h5 style={{ fontWeight: 800, color: "#2C3A50" }}>
                                         NGN {analytics.hasOwnProperty('totalBalanceUserNigeria') ? numberWithCommas(analytics.totalBalanceUserNigeria): 0}
                                        </h5>
                                    </div>

                                    <div className="mt-3">
                                        <h5 style={{ fontWeight: 800, color: "#2C3A50" }}>
                                        GHS {analytics.hasOwnProperty('totalBalanceUserGhana') ? numberWithCommas(analytics.totalBalanceUserGhana): 0}
                                        </h5>
                                    </div>

                                </div>
                        </div>

                        <div className='col-lg-4 mb-4 mb-lg-0'>
                            <div className="dash-div">
                                    <div className="mt-1">
                                         <p>Total Inflow</p>
                                    </div>

                                    <div className="mt-3">
                                        <h5 style={{ fontWeight: 800, color: "#2C3A50" }}>
                                         NGN {analytics.hasOwnProperty('totalInflowNigeria') ? numberWithCommas(analytics.totalInflowNigeria): 0}
                                        </h5>
                                    </div>

                                    <div className="mt-3">
                                        <h5 style={{ fontWeight: 800, color: "#2C3A50" }}>
                                         GHS {analytics.hasOwnProperty('totalInflowGhana') ? numberWithCommas(analytics.totalInflowGhana): 0}
                                        </h5>
                                    </div>
                                </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className="dash-div">
                                    <div className="mt-1">
                                    <p>Total Amount Withdrawn</p>
                                    </div>

                                    <div className="mt-3">
                                        <h5 style={{ fontWeight: 800, color: "#2C3A50" }}>
                                         NGN {analytics.hasOwnProperty('totalWithdrawNigeria') ? numberWithCommas(analytics.totalWithdrawNigeria) : 0}
                                        </h5>                                       
                                    </div>

                                    <div className="mt-3">
                                        <h5 style={{ fontWeight: 800, color: "#2C3A50" }}>
                                         GHS {analytics.hasOwnProperty('totalWithdrawGhana')  ? numberWithCommas(analytics.totalWithdrawGhana): 0}
                                        </h5>                                       
                                    </div>
                                </div>
                        </div>

                    </div>

                    <div className="transactions-container mt-4 mb-5">
                     <div className="transaction-filter-container">
                        <div className="transaction-search">
                            <div className="form-group search-container ">
                            <input
                                className="form-control input-style"
                                type="text"
                                placeholder="Search by Username"
                                id="reference"
                                value={search}
                                onChange={handleChange}
                            />
                            </div>
                         </div>

                         <div className="ml-lg-4 ml-0">
                            <button
                            className="btn filter-div"
                            onClick={() => setFilterModal(true)}
                            >
                            <img
                                src={hamburger}
                                width="20"
                                height="20"
                                alt="hamburger"
                                className="mr-2"
                            />
                            Filter By
                            </button>
                        </div>
                      </div>

                      {/* tags for active filters */}
                      <div className='mt-3 mb-4 filter-tag'>
                        <div className='active-tag'>
                            <p className='active-tag-text'>{category}</p>
                        </div>
                        <div className='active-tag'>
                            <p className='active-tag-text'>From {fromDate} - {toDate}</p>
                        </div>
                      </div>

                         <DataTable
                        title="Users List"
                        columns={columns}
                        data={userData}
                        pagination
                        persistTableHead
                        progressPending={loader}
                        />
                    </div>

                 </div>
           </div>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
      analytics: state.analytic.analytics,
      userData: state.analytic.useranalytics,
      loader: state.analytic.loader
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchAnalytics: () => dispatch(getAdminAnalytics()),
      searchUsers: (val) => dispatch(searchUser(val)),
      fetchUserAnalytics: (type, from, to) => dispatch(getAdminAnalyticsWithFilter(type, from, to)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(UserAnalyticsPage);
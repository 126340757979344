import React,{useEffect, useState} from "react";
import UserSideBar from "../../components/Sidebar/sidebar";
import "../../styles/dashboard.css";
import DataTable from "react-data-table-component";
import { filterRequests, filterWithdrawalRequests, getAllRequests, searchWithdrawalRequests } from "../../store/actions/request";
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import hamburger from "../../assets/images/align-left.svg";
import Modal from "../../components/ModalComponent/Modal";
import { Form, Formik } from "formik";
import { filterValidator } from "../../validationSchema/validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Withdrawals = ({
  getRequest,
  requests,
  requestFilter,
  searchRequest,
  loading,
  filterRequest,
}) => {
  // make call to fetch all withdrawals
  useEffect(() => {
    getRequest();
  }, [getRequest]);

  const history = useHistory();

  const [search, setSearchValue] = useState("");
  const [showFilterModal, setFilterModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [endDate, setEndDate] = useState(new Date());

  const columns = [
    {
      name: "Username",
      selector: (row) => row.userDetails.username,
    },
    {
      name: "Type",
      selector: (row) => row.accountType,
    },
    {
      name: "Acct Number / Mobile Number",
      selector: (row) =>
        `${row.accountType === "Bank" ? row.accountNumber : row.mobileNumber}`,
    },
    {
      name: "Amount",
      cell: (row) => <span>{`${row.userDetails.countryTag === "GH" ? "GH₵ " : "NGN"} ${row.amount}`}</span>,
    },
    {
      name: "Status",
      cell: (row) => (
        <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
      ),
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            ViewRequest(row.id);
          }}
        >
          View
        </button>
      ),
    },
  ];

  const ViewRequest = (id) => {
    requestFilter(id);
    history.push("/request/" + id);
  };

  const handleSubmit = async (values) => {
    let res;
    res = {
      from_date: formatDate(startDate),
      to_date: formatDate(endDate),
      status: values.status,
    };
    setFilterModal(false);
    await filterRequest(res)
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getStatusColor = (val) => {
    let result;
    switch (val) {
      case "Pending":
        result = "pending-icon";
        break;
      case "Processing":
        result = "pending-trade-color";
        break;
      case "Completed":
        result = "completed-icon";
        break;
      case "Failed":
        result = "failed-icon";
        break;
      default:
        result = "failed-icon";
        break;
    }
    return result;
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    searchRequest(search);
  }, [search, searchRequest]);

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          {/* dialog for filter transactions */}
          <Modal
            title="Filter"
            show={showFilterModal}
            onClose={() => setFilterModal(false)}
          >
            <div>
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={filterValidator}
                initialValues={{
                  amount: "",
                  status: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* Date*/}
                    <div className="row mt-4">
                      <div className="col-lg-6">
                        <div className="form-group input-container">
                          <label htmlFor="startDate" style={{color: 'rgb(44, 58, 80)' }}>Start Date</label>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="form-control date-select-style"
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group input-container">
                          <label htmlFor="startDate" style={{color: 'rgb(44, 58, 80)' }}>End Date</label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className="form-control date-select-style"
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                    </div>

                    {/* status */}
                    <div className="form-group mt-lg-2 input-container">
                      <label htmlFor="status" style={{color: 'rgb(44, 58, 80)' }}>Status</label>
                      <select
                        name="status"
                        values={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control date-select-style"
                        id="status"
                      >
                        <option defaultValue="">--Select--</option>
                        <option value="All">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Processing">Processing</option>
                        <option value="Completed">Completed</option>
                        <option value="Declined">Declined</option>
                      </select>
                      <small style={{ color: "#dc3545" }}>
                        {touched.status && errors.status}
                      </small>
                    </div>

                    <div className="text-center mt-4 ">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-login_submit"
                        style={{ fontWeight: "normal" }}
                      >
                        Filter
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
          {/* end of filter dialog */}

          {/* datatable to show recent Transactions */}
          <div className="transactions-container mt-5 mb-5">
            {/* filter layout and search */}
            <div className="transaction-filter-container">
              <div className="transaction-search">
                <div className="form-group search-container mb-3">
                  <input
                    className="form-control input-style"
                    type="text"
                    placeholder="Search by Username, Type or Amount"
                    id="reference"
                    value={search}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="ml-lg-4 ml-0">
                <button
                  className="btn filter-div"
                  onClick={() => setFilterModal(true)}
                >
                  <img
                    src={hamburger}
                    width="20"
                    height="20"
                    alt="hamburger"
                    className="mr-2"
                  />
                  Filter By
                </button>
              </div>
            </div>

            <DataTable
              title="Requests"
              columns={columns}
              data={requests}
              pagination
              persistTableHead
              progressPending={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    requests: state.request.requests,
    loading: state.request.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRequest: () => dispatch(getAllRequests()),
    requestFilter: (id) => dispatch(filterRequests(id)),
    searchRequest: (val) => dispatch(searchWithdrawalRequests(val)),
    filterRequest: (val) => dispatch(filterWithdrawalRequests(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdrawals);

import React, { useState, useRef, useEffect } from "react";
import {useHistory} from 'react-router-dom'
import UserSideBar from "../../components/Sidebar/sidebar";
import "../../styles/dashboard.css";
import { Form, Formik } from "formik";
import { RegisterAdminValidator } from "../../validationSchema/validator";
import { connect } from 'react-redux';
import { clearSignUpState, signUpAdmin } from "../../store/actions/auth";


const Admin = ({ Register, signupsuccess, clearSignUp, role }) => {
  const [initialTab, setTab] = useState(1);

  const history = useHistory();

  const ref = useRef();

  const [tabData] = useState([
    { id: 1, name: "tab-1", text: "New Admin" },
    { id: 2, name: "tab-2", text: "View Admin" },
  ]);

  // tab Layout
  const tabLayout = tabData.map((item) => (
    <div
      key={item.id}
      className={
        initialTab === item.id ? "activeOrderTab tabSpace" : "orderTab tabSpace"
      }
      onClick={() => handleToggle(item.id)}
    >
      <p className="mb-0 text-center">{item.text}</p>
    </div>
  ));

  const handleToggle = (id) => {
    setTab(id);
    if (id === 1) {
      history.push("/admin");
    } else {
      history.push("/admin/all");
    }
  };

  const handleSubmit = async (values, setSubmitting) => {
    await Register(values);
  };

  useEffect(() => {
    if (signupsuccess) {
       ref.current.reset();
      // reset the form
      setTimeout(() => {
        clearSignUp();
      }, 3000);
    }
  }, [signupsuccess, clearSignUp]);
  
  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          {/* order tabs select */}
          <div
            className="mt-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {tabLayout}
          </div>

          <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <div>
                <h4
                  className="text-center"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Create New Admin
                </h4>
              </div>

              {/* form submission */}
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={RegisterAdminValidator}
                initialValues={{
                  email: "",
                  password: "",
                  username: "",
                  phoneNumber: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form ref={ref} onSubmit={handleSubmit}>
                    {/* username */}
                    <div className="form-group input-container mt-4">
                      <label htmlFor="username" style={{color: 'rgb(44, 58, 80)' }}>Username</label>
                      <input
                        className="form-control input-style"
                        type="text"
                        placeholder="Username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="username"
                        style={{ width: "100%" }}
                        value={values.username}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.username && errors.username}
                      </small>
                    </div>

                    {/* phonenumber */}
                    <div className="form-group input-container mt-3">
                      <label htmlFor="email" style={{color: 'rgb(44, 58, 80)' }}>Phone Number</label>
                      <input
                        className="form-control input-style"
                        type="tel"
                        placeholder="Phone Number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="phoneNumber"
                        style={{ width: "100%" }}
                        value={values.phoneNumber}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.phoneNumber && errors.phoneNumber}
                      </small>
                    </div>

                    {/* email address */}
                    <div className="form-group input-container mt-3">
                      <label htmlFor="email" style={{color: 'rgb(44, 58, 80)' }}>Email Address</label>
                      <input
                        className="form-control input-style"
                        type="email"
                        placeholder="Staff Email Address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="email"
                        style={{ width: "100%" }}
                        value={values.email}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.email && errors.email}
                      </small>
                    </div>

                    {/* password */}
                    <div className="form-group input-container mt-3">
                      <label htmlFor="email" style={{color: 'rgb(44, 58, 80)' }}>Password</label>
                      <input
                        className="form-control input-style"
                        type="password"
                        placeholder="Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="password"
                        style={{ width: "100%" }}
                        value={values.password}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.password && errors.password}
                      </small>
                    </div>

                    <button
                      type="submit"
                      disabled={isSubmitting || role === "SubAdmin"}
                      className="btn btn-login_submit btn-block mt-4"
                    >
                      Create New Admin
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    signupsuccess: state.auth.signupsuccess
  };
}

const mapDispatchToProps = (dispatch) =>{
  return {
    Register: (creds) => dispatch(signUpAdmin(creds)),
    clearSignUp: () => dispatch(clearSignUpState()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

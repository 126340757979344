import * as Yup from "yup";

export const loginValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password cannot be less than 6 characters")
    .required("Password is required"),
});

export const twofaCodeValidator = Yup.object({
  code: Yup.string()
  .min(6, "Code cannot be less than 6 characters")
  .max(6, "Code cannot be more than 6 characters")
  .required("Code is required"),
});

export const forgotPasswordValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const seacrchRefValidator = Yup.object({
  reference: Yup.string().required("Transaction Id is required"),
});

export const resetPasswordValidator = Yup.object({
  password: Yup.string()
    .min(6, "Password cannot be less than 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const ChangePasswordValidator = Yup.object({
  password: Yup.string().required("Password is required"),
  newpassword: Yup.string().required("Enter a new password"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
});


export const RegisterAdminValidator = Yup.object({
  username: Yup.string().required("Username is required"),
  phoneNumber: Yup.string()
    .required("Phonenumber is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password cannot be less than 6 characters")
    .required("Password is required"),
});

export const settingsValidator = Yup.object({
  minAmount: Yup.string()
    .required("Minimum Amount is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  maxAmount: Yup.string()
    .required("Maximum Amount is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
    nairaToDollarRate: Yup.string()
    .required("Amount is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
    cedisToDollarRate: Yup.string()
    .required("Amount is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount")
});

export const AddRateValidator = Yup.object({
  coinType: Yup.string()
    .required("Coin Type is required"),
  USDRate: Yup.string()
    .required("USD Rate is required")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  GHSRate: Yup.string()
    .required("GHS Rate is required")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  NGNRate: Yup.string()
    .required("NGN Rate is required")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  minimumUsdValue: Yup.string()
  .required("Minimum USD Rate is required")
  .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  maximumUsdValue: Yup.string()
  .required("Maximum USD Rate is required")
  .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount")
});

export const EditRateValidator = Yup.object({
    editGHSRate: Yup.string()
    .required("GHS Rate is required")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
    editNGNRate: Yup.string()
    .required("NGN Rate is required")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
    editminimumUsdValue: Yup.string()
  .required("Minimum USD Rate is required")
  .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  editmaximumUsdValue: Yup.string()
  .required("Maximum USD Rate is required")
  .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount")
});

export const filterValidator = Yup.object({
  status: Yup.string().required("Select a status"),
  amount: Yup.string().matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
});

export const userValidator = Yup.object({
  category: Yup.string().required("Select a category"),
});


export const pushNotificationvalidator = Yup.object({
  title: Yup.string().required("Title is required"),
  content: Yup.string().required("Content is required"),
});

export const directDebitValidator = Yup.object({
  type: Yup.string().required("Type is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  amount: Yup.string().matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount").required("Amount is required"),
  narration: Yup.string(),
});

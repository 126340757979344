import React from "react";
import "../../styles/login.css";
import Logo from "../../assets/logo/logo-white.svg";
import { Link } from "react-router-dom";
import world from "../../assets/images/world.svg";
import { Form, Formik } from "formik";
import { forgotPasswordValidator } from "../../validationSchema/validator";
import { connect } from "react-redux";
import { forgotPassword } from "../../store/actions/auth";

const ForgotPassword = ({ forgot }) => {
  
  // forgot password func
  const handleSubmit = async (values) => {
    await forgot(values);
  };

  return (
    <>
      <div className="login-container">
        {/* first half */}
        <div className="first_half">
          <div className="first_half_container">
            {/* logo */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link to="/">
                <img
                  src={Logo}
                  style={{ height: "3rem" }}
                  className="img-fluid"
                  alt="Dart Logo"
                />
              </Link>
            </div>

            <div className="forgot-title">
              <h2>Reset Password</h2>
              <h6 className="mt-2" style={{ maxWidth: "24rem" }}>
                Enter your email address and we will help you reset your
                password
              </h6>
            </div>

            {/* form submission */}
            <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={forgotPasswordValidator}
              initialValues={{ email: "" }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className="mt-4 form-container">
                    {/* email address */}
                    <div className="form-group input-container mb-3">
                      <label htmlFor="email">Email Address</label>
                      <input
                        className="form-control input-style"
                        type="email"
                        placeholder="Your Email"
                        id="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.email && errors.email}
                      </small>
                    </div>

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-login_submit"
                      >
                        Send Password Reset Link
                      </button>
                    </div>

                    <div className="mt-3">
                      <p className="login_link text-center">
                        Remember password? <Link to="/">Back to login</Link>
                      </p>
                    </div>

                    <div className="copyright text-center">
                      <p className="mb-0">
                        &copy; {new Date().getFullYear()} DartAfrica All rights
                        reserved.
                      </p>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* second half */}
        <div className="second_half">
            <img src={world} className="img-fluid" alt="sell bitcoins" />
            <div className='login_text'>
                <h4>Unlock a World of <br/> Endless Possibilities.</h4>
            </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgot: (creds) => dispatch(forgotPassword(creds)),
  };
};


export default connect(null, mapDispatchToProps)(ForgotPassword);

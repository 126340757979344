import React,{useEffect, useState} from 'react'
import UserSideBar from "../../components/Sidebar/sidebar";
import DataTable from "react-data-table-component";
import {connect} from 'react-redux'
import Moment from "react-moment";
import {  getKYCSettings, updateKYCSettings } from '../../store/actions/admin';
import Modal from "../../components/ModalComponent/Modal";


const KYCSettingsPage = ({
    kycFetch,
    kycconfig,
    kycUpdate
}) => {

  
    const [showEditModal, setEditModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const columns = [
        {
          name: "Level",
          selector: (row) => row.level,
        },
        {
          name: "Cummulative Withdrawal Limit (USD)",
          selector: (row) => row.cummulativeWithdrawerLimit,
        },
        {
          name: "Daily Withdrawal Limit (USD)",
          selector: (row) => row.dailyWithdrawerLimit,
        },
        {
          name: "Date Created",
          cell: (row) => (
            <span>
              <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
            </span>
          ),
        },
        {
          name: "Requirements",
          selector: (row) => row.requirements.join(','),
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-sm btn-view"
              onClick={() => handleEditModal(row)}
            >
              View
            </button>
          ),
        },
      ];

      const [description, setEditDescription] = useState("");
      const [cumLimit, setEditCumLimit] = useState(0);
      const [dailyLimit, setEditDailyLimit] = useState(0);
      const [id, setID] = useState("");

      const [editDescError, setEditDescError] = useState(false)
      const [editCumError, setEditCumError] = useState(false)
      const [editDailyError, setEditDailyError] = useState(false)

      const handleEditModal = (row) =>{
        setEditDescription(row.description)
        setEditCumLimit(row.cummulativeWithdrawerLimit)
        setEditDailyLimit(row.dailyWithdrawerLimit)
        setID(row.id)
        setEditModal(true)
      }


      const updateKYCSettings = (e) =>{
        e.preventDefault()     

        let res = {
            description: description,
            dailyWithdrawerLimit: parseFloat(dailyLimit),
            cummulativeWithdrawerLimit: parseFloat(cumLimit),
            id: id,
         }
         if(description === ""){
            setEditDescError(true)
          }
          else if(cumLimit === ""){
            setEditCumError(true)
          }
          else if(dailyLimit === ""){
            setEditDailyError(true)
          }
          else{
            //  update kyc settings
            setEditDescError(false)
            setEditCumError(false)
            setEditDailyError(false)
            setLoader(true)
            kycUpdate(res)

            setTimeout(()=>{
                setEditModal(false)
                kycFetch();
                setLoader(false)
            },500)

          }
     }
    

     // make call to fetch all kyc settings
  useEffect(() => {
    kycFetch();
  }, [kycFetch]);


      
    return ( 
        <>
         <UserSideBar />
        <div className="usermain">
            <div className="contain">

                 {/* dialog for edit individual rates */}
         <Modal
            title="Edit"
            show={showEditModal}
            onClose={() => setEditModal(false)}
          >
            <div>
                  <form onSubmit={updateKYCSettings}>
                {/* Description */}
                  <div className="form-group input-container mt-3">
                            <label htmlFor="description" style={{color: 'rgb(44, 58, 80)' }}>Description</label>
                            <input
                              className="form-control input-style"
                              type="text"
                              placeholder="0"
                              id="description"
                              style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                              onChange={(e) => setEditDescription(e.target.value)}
                              value={description}
                            />
                            { editDescError &&  <small style={{ color: "#dc3545" }}>
                              Description is required
                             </small>
                              }
                        </div>

                    {/* Cummulative limit*/}
                    <div className="mt-4">      
                      <div className="form-group input-container mt-3">
                            <label htmlFor="cumLimit" style={{color: 'rgb(44, 58, 80)' }}>Cummulative Withdrawal Limit (USD)</label>
                            <input
                              className="form-control input-style"
                              type="text"
                              placeholder="0"
                              id="cumLimit"
                              style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                              onChange={(e) => setEditCumLimit(e.target.value)}
                              value={cumLimit}
                            />
                           { editCumError && <small style={{ color: "#dc3545" }}>
                             Cummulative Withdrawal Limit is required
                          </small>}
                        </div>

                        {/* daily withdrawal limit */}
                        <div className="form-group input-container mt-3">
                            <label htmlFor="dailyLimit" style={{color: 'rgb(44, 58, 80)' }}>Daily Withdrawal Limit (USD)</label>
                            <input
                              className="form-control input-style"
                              type="text"
                              placeholder="0"
                              id="dailyLimit"
                              style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                              onChange={(e) => setEditDailyLimit(e.target.value)}
                              value={dailyLimit}
                            />
                          { editDailyError && <small style={{ color: "#dc3545" }}>
                           Daily Withdrawal Limit is required
                          </small>
                          }
                        </div>

                        
                    </div>

                    <div className="text-center mt-4 ">
                      <button
                        type="submit"
                        disabled={loader}
                        className="btn btn-login_submit"
                        style={{ fontWeight: "normal" }}
                      >
                        Update
                      </button>
                    </div>
                  </form>
              
            </div>
          </Modal>
          {/* end of modal */}
    
            <div className="transactions-container mt-5 mb-5">
                <DataTable
                title="User KYC"
                columns={columns}
                data={kycconfig}
                pagination
                persistTableHead
                progressPending={false}
                />
            </div>
            </div>
        </div>
        </>
     );
}
 
const mapStateToProps = (state) => {
    return {
      kycconfig: state.admin.kycconfig,
      userRole: state.auth.role,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      kycFetch: (val) => dispatch(getKYCSettings(val)),
      kycUpdate: (val) => dispatch(updateKYCSettings(val)),
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(KYCSettingsPage);
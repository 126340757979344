import { BrowserRouter, Route, Switch } from "react-router-dom";

import LoginPage from "./pages/Login/login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import VerifyInfo from "./pages/VerifyEmail/VerifyInfo";

// private routes
import AdminRoute from "./components/PrivateRoutes/userRoute";
import LoginRoute from "./components/PrivateRoutes/loginRoute";

import NotFoundPage from "./pages/404/NotFound";
import AdminDashboard from "./pages/Dashboard/dashboard";
import Transactions from "./pages/Transactions/transactions";
import Trades from "./pages/Trades/trades";
import Withdrawals from "./pages/Withdrawals/withdrawals";
import Users from "./pages/Users/users";
import Admin from "./pages/Admin/admin";
import Rates from "./pages/Rates/rates";
import WithdrawSettings from "./pages/Settings/withdrawSettings";
import Profile from "./pages/Profile/profile";
import ViewAdmin from "./pages/Admin/ViewAdmin";
import AddRates from "./pages/Rates/AddRates";
import UserDetails from "./pages/Users/userById";
import TradeDetails from "./pages/Trades/tradeDetails";
import TransactionDetails from "./pages/Transactions/TrDetails";
import WithdrawalDetails from "./pages/Withdrawals/WithdrawalDetails";
import EditRates from "./pages/Rates/EditRates";
import Campaign from "./pages/Campaign/campaign";
import UserAnalyticsPage from "./pages/Analytics/analytics";
import TwoAuthSetupPage from "./pages/Login/2faSetup";
import TwoFactorLoginPage from "./pages/Login/2faLogin";
import DirectDebitPage from "./pages/DirectDebit/DirectDebit";
import KYCSettings from "./pages/KYCSettings/KYCSettings";

function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <LoginRoute exact path="/2fa-setup" component={TwoAuthSetupPage} />
            <LoginRoute exact path="/2fa/login" component={TwoFactorLoginPage} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/verifyaccount" component={VerifyInfo} />

            <AdminRoute path="/dashboard" component={AdminDashboard} />
            <AdminRoute path="/transactions" component={Transactions} />
            <AdminRoute
              path="/transaction/:id"
              component={TransactionDetails}
            />
            <AdminRoute path="/trades" component={Trades} />
            <AdminRoute path="/trade/:id" component={TradeDetails} />
            <AdminRoute path="/requests" component={Withdrawals} />
            <AdminRoute path="/request/:id" component={WithdrawalDetails} />
            <AdminRoute path="/users" component={Users} />
            <AdminRoute path="/analytics" component={UserAnalyticsPage} />
            <AdminRoute path="/user/:id" component={UserDetails} />
            <AdminRoute exact path="/admin" component={Admin} />
            <AdminRoute path="/admin/all" component={ViewAdmin} />
            <AdminRoute path="/rates/all" component={Rates} />
            <AdminRoute path="/rates/add" component={AddRates} />
            <AdminRoute path="/rates/edit/:id" component={EditRates} />
            <AdminRoute path="/profile" component={Profile} />
            <AdminRoute path="/settings" component={WithdrawSettings} />
            <AdminRoute path="/kyc-settings" component={KYCSettings} />
            <AdminRoute path="/campaign" component={Campaign} />
            <AdminRoute path="/direct-debit" component={DirectDebitPage} />

            <Route path="*" component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

const initState = {
  trades: [],
  tradesSearch: [],
  trade: {},
  loading: false,
  loader: true
};

const tradeReducer = (state = initState, action) => {
  switch (action.type) {
    case "Trades":
      return {
        ...state,
        trades: action.data,
        tradesSearch: action.data
      };
    case "searchTrade":
      let res = state.tradesSearch.filter((val) => {
        return (
          val.userDetails.username
            .toLowerCase()
            .includes(action.data.toLowerCase()) ||
          val.amounttInCoin.toString().includes(action.data)
        );
      });
      return {
        ...state,
        trades: res,
      };
    case "filterTrades":
      const trade = state.trades.find((val) => val.id === action.data);
      return {
        ...state,
        trade,
      };
    case "Trade":
      return {
        ...state,
        trade: action.data,
      };
    case "startTradeLoad":
      return {
        ...state,
        loader: true
      }
    case "stopTradeLoad":
      return {
        ...state,
        loader: false
      }
    case "startLoad":
      return {
        ...state,
        loading: true,
      };
    case "stopLoad":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default tradeReducer;

import React, {useEffect} from 'react';
import UserSideBar from '../../components/Sidebar/sidebar'
import "../../styles/dashboard.css";
import DataTable from "react-data-table-component";
import { connect } from 'react-redux';
import { getOverviewCount } from '../../store/actions/admin';
import CountUp from "react-countup";
import { filterRequests, getAllRequestWithLimit } from '../../store/actions/request';
import { useHistory } from "react-router-dom";

const AdminDashboard = ({countFetch, count, requests, getRequest, requestFilter, loading }) => {

  // make call to fetch all settings
  useEffect(() => {
     countFetch();
      getRequest();
  }, [ countFetch, getRequest]);

  const history = useHistory();

const columns = [
  {
    name: "Username",
    selector: (row) => row.userDetails.username,
  },
  {
    name: "Type",
    selector: (row) => row.accountType,
  },
  {
    name: "Acct Number / Mobile Number",
    selector: (row) =>
      `${row.accountType === "Bank" ? row.accountNumber : row.mobileNumber}`,
  },
  {
    name: "Amount",
    cell: (row) => <span>{`${row.userDetails.countryTag === "GH" ? "GH₵ " : "NGN"} ${row.amount}`}</span>,
  },
  {
    name: "Status",
    cell: (row) => (
      <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
    ),
  },
  {
    name: "Actions",
    button: true,
    cell: (row) => (
      <button
        className="btn btn-sm btn-view"
        onClick={() => {
          ViewRequest(row.id);
        }}
      >
        View
      </button>
    ),
  },
  ];
  
  
  const getStatusColor = (val) => {
    let result;
    switch (val) {
      case "Pending":
        result = "pending-icon";
        break;
      case "Successful":
        result = "completed-icon";
        break;
      case "Failed":
        result = "failed-icon";
        break;
      default:
        result = "failed-icon";
        break;
    }
    return result;
  };


  const ViewRequest = (id) => {
     requestFilter(id);
     history.push("/request/" + id);
  };

  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
         
          <div className="row mt-5">
            {/* Total payout */}
            {/* <div className="col-lg-4 mb-4">
              <div className="dash-div">
                <div className="mt-1">
                  <h6>Total Outflow</h6>
                </div>

                <div className="mt-3">
                  <h4 style={{ fontWeight: 800, color: "#2C3A50" }}>
                    <CountUp
                      start={0}
                      end={count.totalOutflow ? count.totalOutflow : 0}
                      duration={2.5}
                      separator=","
                    />
                  </h4>
                </div>
              </div>
            </div> */}

            {/* Users */}
            <div className="col-lg-4 mb-4">
              <div className="dash-div">
                <div className="mt-1">
                  <h6 style={{ color: "#2C3A50" }}>Completed Trades</h6>
                </div>

                <div className="mt-3">
                  <h4 style={{ fontWeight: 800, color: "#2C3A50" }}>
                    <CountUp
                      start={0}
                      end={
                        count.countCompletedTrade
                          ? count.countCompletedTrade
                          : 0
                      }
                      duration={2.5}
                      separator=","
                    />
                  </h4>
                </div>
              </div>
            </div>

            {/* requests */}
            <div className="col-lg-4 mb-4">
              <div className="dash-div">
                <div className="mt-1">
                  <h6 style={{ color: "#2C3A50" }}>Pending Request(s)</h6>
                </div>

                <div className="mt-3">
                  <h4 style={{ fontWeight: 800, color: "#2C3A50" }}>
                    <CountUp
                      start={0}
                      end={
                        count.countWithdrawerRequest
                          ? count.countWithdrawerRequest
                          : 0
                      }
                      duration={2.5}
                      separator=","
                    />
                  </h4>
                </div>
              </div>
            </div>

            {/* users */}
            <div className="col-lg-4 mb-4">
              <div className="dash-div">
                <div className="mt-1">
                  <h6 style={{ color: "#2C3A50" }}>Users</h6>
                </div>

                <div className="mt-3">
                  <h4 style={{ fontWeight: 800, color: "#2C3A50" }}>
                    <CountUp
                      start={0}
                      end={count.countTotalUsers ? count.countTotalUsers : 0}
                      duration={2.5}
                      separator=","
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>

          {/* datatable to show recent withdrawal requests */}
          <div className="mt-4 mb-5">
            <DataTable
              title="Recent Request(s)"
              columns={columns}
              data={requests}
              pagination
              persistTableHead
              progressPending={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    count: state.admin.count,
    requests: state.request.requests,
    loading: state.request.loading,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    countFetch: () => dispatch(getOverviewCount()),
    getRequest: () => dispatch(getAllRequestWithLimit()),
    requestFilter: (id) => dispatch(filterRequests(id)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
const initState = {
  rates: [],
  ratesuccess: false,
  cointypes: []
};

const rateReducer = (state = initState, action) => {
  switch (action.type) {
    case "Rates":
      return {
        ...state,
        rates: action.data,
      };
    case "CoinTypes":
      return {
        ...state,
        cointypes: action.data
      }
    case "Rate_Success":
      return {
        ...state,
        ratesuccess: true,
      };
    case "DeleteRate":
      const res = state.rates.filter((item) => item.id !== action.data);
      return {
        ...state,
        rates: res
      }
    case "Rate_Fail":
      return {
        ...state,
        ratesuccess: false,
      };
    case "ClearRateSuccess":
      return {
        ...state,
        ratesuccess: false,
      };
    default:
      return state;
  }
};

export default rateReducer;

import {  PatchApi } from "../request";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

//direct debit
export const directDebit = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await PatchApi(
        "direct/debit",
        {
            email : val.email,
            amount: parseFloat(val.amount),
            narration: val.narration
        },
        getToken(),
        "application/json"
      );
      if (res.status === 200) {
         dispatch({ type: "Debit_Success" });
          cogoToast.success("Debit successfully made!", {
          position: "bottom-right",
        });
      }
      if (res.status === 400 || res.status === 404) {
        dispatch({ type: "Debit_Fail" });
        cogoToast.error("Error while debiting user!");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// direct credit
export const directCredit = (val) => {
    return async (dispatch, getState) => {
      try {
        const res = await PatchApi(
          "credit/wallet",
          {
              email : val.email,
              amount: parseFloat(val.amount),
              narration: val.narration
          },
          getToken(),
          "application/json"
        );
        if (res.status === 200) {
           dispatch({ type: "Credit_Success" });
            cogoToast.success("Credit successfully made!", {
            position: "bottom-right",
          });
        }
        if (res.status === 400 || res.status === 404) {
          dispatch({ type: "Credit_Fail" });
          cogoToast.error("Error while crediting user!");
        }
      } catch (err) {
        console.log(err);
      }
    };
  };


// reset campaign success
export const clearDebit = () => {
  return (dispatch, getState) => {
    dispatch({ type: "clearDebit" });
  };
};


import React,{useEffect} from 'react'
import UserSideBar from "../../components/Sidebar/sidebar";
import { useFormik } from "formik";
import { directDebitValidator } from "../../validationSchema/validator";
import {connect} from 'react-redux'
import { clearDebit, directCredit, directDebit } from '../../store/actions/direct';

const DirectDebitPage = ({ handleDebit,handleCredit, loading, handleClear}) => {
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      } = useFormik({
        initialValues: {
          type: "",
          email: "",
          amount: "",
          narration: ""
        },
        onSubmit(values, { setSubmitting }) {
          handSubmit(values, setSubmitting);
        },
        validationSchema: directDebitValidator,
      });
    
      const handSubmit = async (values, setSubmitting) => {
        console.log(values)
        if(values.type === "Debit"){
            await handleDebit(values);
        
            setTimeout(() => {
              setSubmitting(false);
            }, 2000);
        }
        else{
            await handleCredit(values);
    
            setTimeout(() => {
            setSubmitting(false);
            }, 2000);
        }
      
      };

      useEffect(() => {
        if (loading) {
            setFieldValue("email", "");
            setFieldValue("type", "");
            setFieldValue("amount", "");
            setFieldValue("narration", "");
            setFieldTouched("amount", false)
            setFieldTouched("email", false)
            setFieldTouched("type", false)
          setTimeout(() => {
           handleClear();
          }, 2000);
        }
      }, [loading, handleClear, setFieldTouched, setFieldValue]);

    return ( 
        <>
             <UserSideBar />
      <div className="usermain">
        <div className="contain">
          <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <div>
                <h4
                  className="text-center"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Direct Debit/Credit
                </h4>
              </div>

              <form onSubmit={handleSubmit}>
              

                {/* select type */}
                <div className="form-group input-container">
                      <label htmlFor="type" style={{color: 'rgb(44, 58, 80)' }}>Select Type</label>
                      <select
                        name="type"
                        values={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control  date-select-style"
                        style={{color: 'rgb(44, 58, 80)', borderColor: '#454545', background: 'transparent' }}
                        id="type"
                        defaultValue=""
                      >
                        <option value="" disabled selected>--Select--</option>
                        <option value="Debit">Debit</option>
                        <option value="Credit">Credit</option>
                      </select>
                      <small style={{ color: "#dc3545" }}>
                        {touched.type && errors.type}
                      </small>
                    </div>

                {/* Enter user's emaill addresss */}
                <div className="form-group input-container mt-3">
                  <label htmlFor="email" style={{color: 'rgb(44, 58, 80)'}}>Enter user's email address</label>
                  <input
                    className="form-control input-style"
                    type="email"
                    placeholder="Enter user's email address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="email"
                    style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                    value={values.email}
                  />
                  <small style={{ color: "#dc3545" }}>
                    {touched.email && errors.email}
                  </small>
                </div>

                {/* enter amount */}
                <div className="form-group input-container mt-3">
                  <label htmlFor="amount" style={{color: 'rgb(44, 58, 80)'}}>Enter amount</label>
                  <input
                    className="form-control input-style"
                    type="text"
                    placeholder="Enter amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="amount"
                    style={{ width: "100%",color: 'rgb(44, 58, 80)' }}
                    value={values.amount}
                  />
                  <small style={{ color: "#dc3545" }}>
                    {touched.amount && errors.amount}
                  </small>
                </div>


                {/* content */}
                <div className="form-group input-container mt-3">
                  <label htmlFor="narration" style={{color: 'rgb(44, 58, 80)'}}>Narration</label>
                  <textarea
                    rows="5"
                    className="form-control input-style"
                    id="narration"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.narration}
                    placeholder="Enter a narration"
               
                    style={{ width: "100%",color: 'rgb(44, 58, 80)', resize: 'none' }}
                  ></textarea>

                  <small style={{ color: "#dc3545" }}>
                    {touched.narration && errors.narration}
                  </small>
                </div>


                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-login_submit btn-block mt-4"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
        </>
     );
}
 
const mapStateToProps = (state) => {
    return {
      loading: state.debit.loading
    }
  }
  

const mapDispatchToProps = (dispatch) => {
    return {
      handleDebit: (creds) => dispatch(directDebit(creds)),
      handleCredit: (creds) => dispatch(directCredit(creds)),
      handleClear: () => dispatch(clearDebit()),
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(DirectDebitPage);
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

const LoginRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <>
            <Redirect to={"/"} />
          </>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    validcode: state.auth.validauthcode,
  };
};

export default connect(mapStateToProps)(LoginRoute);

import React from "react";
import UserSideBar from "../../components/Sidebar/sidebar";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { resolveTrade } from "../../store/actions/trades";

const TradeDetails = ({ trade, resolve, loading }) => {

  const Resolve = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `You are about to resolve this trade, Do you wish to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmResolve(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const confirmResolve = (id) => {
    const val = {
      tradeId: id
    };
    resolve(val)
  }
  
 
   const getStatusColor = (val) => {
     let result;
     switch (val) {
       case "Created":
         result = "pending-trade-color";
         break;
       case "Confirmed":
         result = "completed-trade-color";
         break;
       case "Failed":
         result = "failed-trade-color";
         break;
       default:
         result = "failed-trade-color";
         break;
     }
     return result;
  };
  
  return (
    <>
      <UserSideBar />
      <div className="usermain">
        <div className="contain">
          <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <Link
                to="/trades"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#FF8400",
                  textDecoration: "none",
                }}
              >
                <i
                  className="mdi mdi-chevron-left mr-1"
                  style={{ fontSize: 20 }}
                ></i>
                <p className="mb-0" style={{ fontSize: 14 }}>
                  Go Back
                </p>
              </Link>

              <div>
                <h4
                  className="text-center mt-3 mt-md-2"
                  style={{ color: "#2D2D2D", fontWeight: "bold" }}
                >
                  Trade Details
                </h4>
              </div>

              {/* details */}
              <div className="row mt-md-5 mt-4">
                {/* id and time */}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Trade Id
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {trade ? trade.id : "12345"}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Date
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {trade ? (
                        <Moment format="MMMM Do, YYYY, h:mm:ss a">
                          {trade.createdAt}
                        </Moment>
                      ) : (
                        "Trade Date"
                      )}
                    </p>
                  </div>
                </div>

                {/* username and wallet address */}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Username
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {trade ? trade.userDetails.username : "Ade"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Wallet Address
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {trade ? trade.walletAddress : "23344111adff"}
                    </p>
                  </div>
                </div>

                {/* currency and type */}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Currency Traded
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {trade ? trade.coinType : "Coin"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Trade Type
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      Sell
                    </p>
                  </div>
                </div>

                {/* amount */}
                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Amount Traded
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {trade
                        ? `${trade.amounttInCoin} ${trade.coinType}`
                        : "Coin"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Amount
                    </p>
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "#2C3A50", fontSize: 14 }}
                    >
                      {trade.userDetails.countryTag === "GH" ? "GHS " : "NGN"} {trade ? `${trade.amountInLocalCurrency}` : "0"}
                    </p>
                  </div>
                </div>
                {/* status */}
                <div className="col-lg-6">
                  <div>
                    <p
                      className="mb-0"
                      style={{ color: "#2C3A50", fontWeight: "bold" }}
                    >
                      Trade Status
                    </p>
                    <div className="mt-2" style={{ display: "flex" }}>
                      <p
                        className={getStatusColor(
                          trade ? trade.tradeStatus : "Failed"
                        )}
                        style={{ fontSize: 14 }}
                      >
                        {trade ? trade.tradeStatus : "Status"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {trade.tradeStatus === "Unresolved" ? (
                <div className="text-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-login_submit"
                    style={{ width: "auto" }}
                    disabled={loading}
                    onClick={() => {
                      Resolve(trade.id);
                    }}
                  >
                    Resolve Trade
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  //  const id = ownProps.match.params.id;
  //  const trades = state.trades.trades;
  // const trade = trades.find((val) => val.id === id);
  return {
    trade: state.trades.trade,
    loading: state.trades.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resolve: (val) => dispatch(resolveTrade(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeDetails);
